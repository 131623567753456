<script lang="ts" setup>
  import { ref } from 'vue';
  import type { UserTooltipProps } from '../../types';
  import { Drawer } from '@/shared/ui/drawer';
  import { fromNow } from '@/utils';
  import Roles from './Roles.vue';
  import { UserAvatar } from '@/shared/ui/user-avatar';
  import FollowButton from '@/components/buttons/FollowButton.vue';
  import { useRouter } from 'vue-router';
  import { USER_CONTENT } from '@/common';
  import GoodbyeButton from './GoodbyeButton.vue';
  import { isPortalManager, isPortalOwner } from '@/shared/lib';
  import UserSummary from './UserSummary.vue';
  import { useTooltip } from '../../model/composables/use-tooltip';
  import { has } from 'lodash-es';

  const props = defineProps<UserTooltipProps>();

  const {
    assignableRoles,
    getFormattedTokens,
    isAdmin,
    isEnabled,
    isUserFollowing,
    onAddNewRoleToUser,
    onClickFollowingFollower,
    onGoodbye,
    refetchIsUserFollowingQuery,
    userPortalRolesDto,
  } = useTooltip(props);
  const router = useRouter();

  const isDrawerOpened = ref(false);

  const onClickedProfile = () => {
    isDrawerOpened.value = true;
    isEnabled.value = true;
  };
</script>

<template>
  <user-summary v-bind="props" @clicked-profile="onClickedProfile" />
  <drawer
    v-if="isDrawerOpened"
    v-model:opened="isDrawerOpened"
    direction="bottom"
    custom-classes="w-full max-h-full mt-2 overflow-y-auto thin-scrollbar shadow-lg bg-gray-950 rounded-t-md focus:outline-none"
    :show-header="false"
  >
    <div class="relative p-4">
      <div class="absolute top-4 left-4" @click="isDrawerOpened = false">
        <base-icon name="close" size="size-5" />
      </div>

      <div class="flex flex-col gap-2 items-center pt-9">
        <user-avatar :item="user" size="size-32" />

        <div class="flex flex-col items-center">
          <base-button
            variant="custom"
            custom-classes="flex items-center font-bold mt-4 text-gray-300 hover:text-indigo-300 text-2xl leading-7"
            :to="{
              name: USER_CONTENT,
              params: {
                username: user.username,
              },
            }"
          >
            <span v-if="isMention">@</span>{{ user.username }}
            <base-icon name="chevron-right" />
          </base-button>

          <div
            class="mt-3 text-base font-semibold leading-5 text-center text-gray-300"
          >
            {{ user.bio }}
          </div>

          <div class="flex gap-2 mt-3">
            <div
              class="flex items-center gap-1 px-2 py-1 text-sm rounded-md bg-gray-785"
            >
              <base-icon name="dscvr" size="size-4" />
              {{ getFormattedTokens(user.rights) }}
            </div>

            <div
              class="flex items-center gap-1 px-2 py-1 text-sm rounded-md bg-gray-785"
            >
              <base-icon name="clock" />
              Joined {{ fromNow(user.created_at) }}
            </div>
          </div>
        </div>

        <div class="flex justify-center gap-4 mt-3">
          <base-button
            variant="link"
            custom-classes="hover:underline follow-link"
            @click="onClickFollowingFollower('following')"
          >
            {{ user.following }}

            <span class="text-blue-400">Following</span>
          </base-button>

          <base-button
            variant="link"
            custom-classes="hover:underline follow-link"
            @click="onClickFollowingFollower('followers')"
          >
            {{ user.followers }}

            <span class="text-blue-400">Followers</span>
          </base-button>
        </div>
        <template v-if="isAdmin">
          <goodbye-button @goodbye="onGoodbye" />
        </template>
        <template v-if="has(portalInfoView.portal_type, 'Portal')">
          <roles
            :is-owner="isPortalOwner(portalInfoView, user)"
            :is-portal-manager="isPortalManager(portalInfoView)"
            :portal-info-view="portalInfoView"
            :user-portal-roles="userPortalRolesDto?.roles"
            :available-assignable-roles="assignableRoles"
            @on-add-new-role-to-user="onAddNewRoleToUser($event)"
          />
        </template>
        <follow-button
          :is-following="isUserFollowing"
          :user-id="user.id"
          size="lg"
          class="mt-4"
          @follow-user="refetchIsUserFollowingQuery"
          @login-modal-shown="isDrawerOpened = false"
        />
      </div>
    </div>
  </drawer>
</template>
