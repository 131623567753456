<script setup lang="ts">
  import { computed } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { BaseDropdown } from '@/shared/ui/base-dropdown';
  import { PermissionFlags, hasPermission } from '@/shared/lib';
  import { useUserRole } from '@/entities/user';
  import type { CommentViewNode } from '@/shared/model';

  const props = defineProps<{
    comment: CommentViewNode;
    canDelete: boolean;
    canEdit: boolean;
  }>();

  const emits = defineEmits<{
    (e: 'report'): void;
    (e: 'remove'): void;
    (e: 'delete'): void;
    (e: 'edit'): void;
    (e: 'set-nsfw', value: boolean): void;
  }>();

  const { t } = useI18n({ useScope: 'global' });
  const { isAdmin } = useUserRole();

  const isContentModerator = computed(() => {
    return hasPermission(props.comment.perm, PermissionFlags.MODERATE_CONTENT);
  });

  const deleteContent = () => {
    if (confirm(t('deleteConfirmationMessage'))) {
      emits('delete');
    }
  };

  const removeContent = () => {
    if (confirm(t('removeConfirmationMessage'))) {
      emits('remove');
    }
  };

  const buttonCustomClasses =
    'flex justify-start items-center gap-2 hover:bg-gray-600 hover:text-gray-200 p-3 w-full';
</script>

<template>
  <base-dropdown
    placement="bottom-end"
    :distance="10"
    :skidding="10"
    custom-content-classes="flex flex-col justify-center items-start text-gray-400 bg-gray-975  border border-gray-785 border-opacity-50 rounded-md shadow-lg focus:outline-none"
  >
    <template #button="{ toggle }">
      <base-button
        variant="custom"
        custom-classes="btn-comment-actions group/comment"
        @click="toggle"
      >
        <base-icon
          name="ellipsis"
          size="size-4"
          class="text-gray-400 group-hover/comment:text-white"
        />
      </base-button>
    </template>

    <template #content="{ hide }">
      <base-button
        variant="custom"
        rounded="rounded-none"
        :custom-classes="buttonCustomClasses"
        size="small"
        @click="emits('report'), hide()"
      >
        <base-icon name="flag" size="size-4" />

        {{ t('report') }}
      </base-button>
      <!-- edit -->
      <base-button
        v-if="canEdit"
        variant="custom"
        rounded="rounded-none"
        :custom-classes="buttonCustomClasses"
        size="small"
        @click="emits('edit'), hide()"
      >
        <base-icon name="pencil" size="size-4" />
        {{ t('edit') }}
      </base-button>

      <!-- delete -->
      <base-button
        v-if="canDelete"
        variant="custom"
        rounded="rounded-none"
        :custom-classes="buttonCustomClasses"
        @click="deleteContent(), hide()"
      >
        <base-icon name="trash" size="size-4" />

        {{ t('delete') }}
      </base-button>
      <base-button
        v-if="isAdmin"
        variant="custom"
        rounded="rounded-none"
        :custom-classes="buttonCustomClasses"
      >
        <input
          :value="comment.is_nsfw"
          type="checkbox"
          class="form-checkbox"
          @click="emits('set-nsfw', !comment.is_nsfw), hide()"
        />

        {{ t('markAsNSFW') }}
      </base-button>

      <base-button
        v-if="isAdmin || isContentModerator"
        variant="custom"
        rounded="rounded-none"
        :custom-classes="buttonCustomClasses"
        @click="removeContent(), hide()"
      >
        <base-icon name="remove" size="size-4" />

        {{ t('remove') }}
      </base-button>
    </template>
  </base-dropdown>
</template>
