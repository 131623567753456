<script lang="ts" setup>
  import { watch, ref } from 'vue';
  import ReplyItem from './Item.vue';
  import type { CommentViewNode } from '@/shared/model';
  import { useElementSize } from '@vueuse/core';

  withDefaults(
    defineProps<{
      comments: CommentViewNode[];
      showReplies: boolean;
      isSuperAdmin?: boolean;
    }>(),
    {
      isSuperAdmin: false,
    },
  );

  const repliesThread = ref<HTMLDivElement | null>(null);

  const { height } = useElementSize(repliesThread);
</script>

<template>
  <div
    class="relative overflow-hidden transition-all duration-300 ease-in-out"
    :style="{ maxHeight: showReplies ? `${height + 20}px` : '0' }"
  >
    <div ref="repliesThread" class="relative flex flex-col pt-5 pb-4 gap-2">
      <reply-item
        v-for="comment in comments"
        :key="comment.id.toString()"
        :comment="comment"
        :is-super-admin="isSuperAdmin"
        :is-first-item="comments[0].id === comment.id"
        :is-last-item="comments[comments.length - 1].id === comment.id"
      />
    </div>
  </div>
</template>
