import type { Ref, MaybeRef } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { dscvrIcApi } from '@/shared/api';
import { getPortalBySlugKey } from './keys';

export const useGetPortalBySlugQuery = (
  slug: Ref<string>,
  enabled: MaybeRef<boolean>,
) => {
  return useQuery({
    queryKey: [...getPortalBySlugKey(slug)],
    queryFn: async () => {
      const { status, result } = await dscvrIcApi.portal.getPortal(slug.value);
      if (status === 'happy' && result.length > 0) {
        return result[0];
      } else {
        return null;
      }
    },
    staleTime: 1000 * 60 * 5,
    enabled,
  });
};
