<script setup lang="ts">
  import { ref } from 'vue';

  const sortDropdownOpen = ref(false);
  const sortValues = ref([
    { label: 'Default', key: 'default' },
    { label: 'Live', key: 'live' },
    { label: 'OP', key: 'op' },
    { label: 'Newest', key: 'newest' },
    { label: 'Oldest', key: 'oldest' },
    { label: 'Verified PFP', key: 'verified_pfp' },
  ]);
  const sortValue = ref({ label: 'Default', key: 'default' });

  const emitSortValue = (sortValueEl: typeof sortValue.value) => {
    sortValue.value = sortValueEl;
    sortDropdownOpen.value = false;
    emit('sort-selected', sortValue.value.key);
  };

  const emit = defineEmits(['sort-selected']);
</script>

<template>
  <div class="relative">
    <span
      class="gap-2 btn-comment-section"
      @click="sortDropdownOpen = !sortDropdownOpen"
    >
      <span v-if="sortValue.key == 'live'" id="box" class="text-red-500">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="18"
          stroke="currentColor"
          viewBox="0 0 24 18"
        >
          <g fill="#FF3333" fill-rule="evenodd">
            <ellipse cx="12" cy="8.705" rx="3" ry="3" />
            <path
              id="on-air-out"
              d="M3.51471863.219669914C-1.17157288 4.90596141-1.17157288 12.5039412 3.51471863 17.1902327 3.80761184 17.4831259 4.28248558 17.4831259 4.5753788 17.1902327 4.86827202 16.8973394 4.86827202 16.4224657 4.5753788 16.1295725.474873734 12.0290674.474873734 5.38083515 4.5753788 1.28033009 4.86827202.987436867 4.86827202.512563133 4.5753788.219669914 4.28248558-.0732233047 3.80761184-.0732233047 3.51471863.219669914zM20.4852814 17.1902327C25.1715729 12.5039412 25.1715729 4.90596141 20.4852814.219669914 20.1923882-.0732233047 19.7175144-.0732233047 19.4246212.219669914 19.131728.512563133 19.131728.987436867 19.4246212 1.28033009 23.5251263 5.38083515 23.5251263 12.0290674 19.4246212 16.1295725 19.131728 16.4224657 19.131728 16.8973394 19.4246212 17.1902327 19.7175144 17.4831259 20.1923882 17.4831259 20.4852814 17.1902327z"
            />
            <path
              id="on-air-in"
              d="M17.3033009 14.0082521C18.7217837 12.5897693 19.4928584 10.6983839 19.4999509 8.73215792 19.507111 6.74721082 18.7352286 4.8335782 17.3033009 3.40165043 17.0104076 3.10875721 16.5355339 3.10875721 16.2426407 3.40165043 15.9497475 3.69454365 15.9497475 4.16941738 16.2426407 4.4623106 17.3890249 5.6086948 18.0056933 7.13752465 17.9999607 8.72674718 17.9942823 10.30094 17.3782748 11.8119579 16.2426407 12.947592 15.9497475 13.2404852 15.9497475 13.7153589 16.2426407 14.0082521 16.5355339 14.3011454 17.0104076 14.3011454 17.3033009 14.0082521zM6.69669914 3.40165043C3.76776695 6.33058262 3.76776695 11.07932 6.69669914 14.0082521 6.98959236 14.3011454 7.46446609 14.3011454 7.75735931 14.0082521 8.05025253 13.7153589 8.05025253 13.2404852 7.75735931 12.947592 5.41421356 10.6044462 5.41421356 6.80545635 7.75735931 4.4623106 8.05025253 4.16941738 8.05025253 3.69454365 7.75735931 3.40165043 7.46446609 3.10875721 6.98959236 3.10875721 6.69669914 3.40165043z"
            />
          </g>
        </svg>
      </span>

      {{ sortValue.label }}
      <base-icon name="chevron-down-filled" />
    </span>

    <div
      v-show="sortDropdownOpen"
      class="absolute z-20 w-48 mt-2 sortTopDropdown"
    >
      <div class="z-20 flex flex-col bg-gray-700 rounded-lg shadow-xl">
        <a
          v-for="(sortValueEl, index) in sortValues"
          :key="index"
          class="inline-flex items-center px-4 py-2 text-sm text-white capitalize cursor-pointer hover:bg-gray-500"
          :class="{
            'rounded-t-lg': index === 0,
            'rounded-b-lg': index === 4,
          }"
          @click="emitSortValue(sortValueEl)"
        >
          <div
            class="w-4 h-4 mr-2 rounded-full"
            :class="{
              'bg-white border-4 border-pink-600':
                sortValueEl.key === sortValue.key,
              'bg-gray-600': sortValueEl.key !== sortValue.key,
            }"
          ></div>
          {{ sortValueEl.label }}
        </a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  #box {
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    > svg {
      height: 24px;
      width: 24px;

      path#on-air-out {
        animation: on-air-out 2s infinite;
      }

      path#on-air-in {
        animation: on-air-in 2s infinite;
      }

      ellipse {
        transform-origin: 50% 50%;
        animation: on-air-circle 2s infinite;
      }
    }
  }

  @keyframes on-air-circle {
    0% {
      opacity: 0.1;
      transform: scale(1);
    }
    25% {
      opacity: 1;
      transform: scale(2.4);
    }
    50%,
    75% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0.3;
    }
  }

  @keyframes on-air-in {
    0%,
    50% {
      opacity: 0.3;
    }
    75%,
    100% {
      opacity: 1;
    }
  }

  @keyframes on-air-out {
    0%,
    50% {
      opacity: 0.3;
    }
    75%,
    100% {
      opacity: 1;
    }
  }
</style>
