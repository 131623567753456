<script lang="ts" setup>
  import { computed, ref, type Ref, watch } from 'vue';
  import { useUser } from '@/entities/user';
  import type {
    ContentView,
    ContentSort,
    ContentQuery,
  } from 'dfx/edge/edge.did';
  import { PermissionFlags, hasPermission } from '@/shared/lib';
  import { useRoute } from 'vue-router';
  import { Loader } from '@/shared/ui/loader';
  import { UserAvatar } from '@/shared/ui/user-avatar';
  import { useGetCommentsQuery } from '@/entities/comment';
  import CommentSection from '../ui/Section.vue';
  import CommentForm from './Form.vue';
  import CommentFeedThread from './feed/Thread.vue';
  import SortBy from './SortBy.vue';
  import SingleThreadView from './SingleThreadView.vue';
  import { useCommentStore } from '@/shared/model';
  import { storeToRefs } from 'pinia';
  import { useComment } from '../model/composables/use-comment';

  const props = defineProps<{
    content: ContentView;
  }>();

  const route = useRoute();
  const { currentUser } = useUser();
  const commentStore = useCommentStore();
  const { singleThreadViewItem, comments } = storeToRefs(commentStore);
  const { isCommenting, createComment } = useComment();

  const sortValue: Ref<ContentSort> = ref({ Default: null });

  const query = computed(
    () =>
      ({
        max_grand_child_depth: [],
        max_grand_children_per_level: [],
        content_id: props.content.id,
        thread_start: BigInt(0),
        thread_size: BigInt(25),
        since: [],
        sort: sortValue.value,
      } as ContentQuery),
  );

  const {
    data: commentsDto,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useGetCommentsQuery(query);

  const canComment = computed(() => {
    return (
      props.content &&
      hasPermission(props.content.perm, PermissionFlags.CREATE_CONTENT_COMMENT)
    );
  });

  const getContentSort = (sort: string): ContentSort => {
    switch (sort.toLowerCase()) {
      case 'newest':
        return { New: null };
      case 'live':
        return { Live: null };
      case 'op':
        return { OP: null };
      case 'oldest':
        return { Old: null };
      case 'verified_pfp':
        return { VerifiedPfp: null };
      default:
        return { Default: null };
    }
  };

  const sortSelected = (sort: string) => {
    sortValue.value = getContentSort(sort);
  };

  const onCreateComment = (body: string) => {
    isCommenting.value = true;
    createComment({
      portalId: props.content.portal.id,
      parentId: props.content.id,
      body,
    });
  };

  const onFetchNextPage = () => {
    if (!isFetchingNextPage.value && hasNextPage.value) {
      fetchNextPage();
    }
  };

  watch(
    commentsDto,
    (value) => {
      if (!value) return;
      commentStore.onSetComments(value);
    },
    {
      immediate: true,
    },
  );

  watch(
    query,
    (newQuery, oldQuery) => {
      if (newQuery === oldQuery) return;
      commentStore.onUpdateSavedQuery(newQuery);
      commentStore.setQueryFilters(newQuery);
    },
    {
      immediate: true,
    },
  );
</script>

<template>
  <single-thread-view v-if="singleThreadViewItem" />
  <comment-section v-else>
    <template #form>
      <loader
        variant="rainbow"
        size="size-8"
        border-width="border"
        v-if="isCommenting"
      />
      <template v-if="canComment">
        <div
          class="flex justify-between w-full p-4 bg-gray-990 rounded-xl gap-4"
        >
          <user-avatar
            v-if="currentUser"
            :item="currentUser"
            size="size-12"
            class="mt-2"
          />
          <comment-form
            ref="commentFormRef"
            v-model:disabled="isCommenting"
            :autofocus="Boolean(route.query.autofocus)"
            @submit="onCreateComment($event)"
          />
        </div>
      </template>
    </template>
    <template #feed>
      <sort-by @sort-selected="sortSelected" />
      <div
        v-if="isLoading"
        class="flex items-center justify-center h-20 my-4 relative"
      >
        <loader variant="rainbow" size="size-8" border-width="border" />
      </div>
      <template v-else>
        <comment-feed-thread
          v-if="comments"
          :comments="comments"
          @fetch-next-page="onFetchNextPage"
        />
      </template>
    </template>
  </comment-section>
</template>
