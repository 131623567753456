import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { ContentQuery } from 'dfx/edge/edge.did';
import type { CommentFilter, CommentViewNode } from './types';

export const useCommentStore = defineStore('comment', () => {
  const comments = ref<CommentViewNode[]>();
  const commentState = ref<Map<bigint, { hidden: boolean }>>(
    new Map<bigint, { hidden: boolean }>(),
  );
  const savedQuery = ref<ContentQuery | null>(null);
  const queryFilters = ref<CommentFilter | null>(null);
  const commentThreadHistory = ref<CommentViewNode[]>([]);
  const singleThreadViewItem = ref<CommentViewNode | null>(null);
  const savedCommentIndexInFeed = ref(new Map<string, number>());

  const setQueryFilters = (query: ContentQuery) => {
    queryFilters.value = {
      content_id: query.content_id.toString(),
      thread_start: query.thread_start.toString(),
      thread_size: query.thread_size.toString(),
      since: [],
      sort: query.sort,
    };
  };

  const onSetComments = (newComments: CommentViewNode[]) => {
    comments.value = newComments.map((comment) => {
      const state = getCommentState(comment.id);

      return {
        ...comment,
        children: comment.children?.map((child) => {
          const childState = getCommentState(child.id);
          return {
            ...child,
            hidden: childState.hidden,
          };
        }),
        hidden: state.hidden,
      };
    });
  };

  const setCommentState = (id: bigint, state: { hidden: boolean }) => {
    commentState.value.set(id, state);
  };

  const getCommentState = (id: bigint) => {
    return commentState.value.get(id) || { hidden: false };
  };

  const toggleHidden = (id: bigint) => {
    const currentState = getCommentState(id);
    setCommentState(id, { ...currentState, hidden: !currentState.hidden });
  };

  const onUpdateSavedQuery = (query: ContentQuery) => {
    savedQuery.value = query;
  };

  const onSetSingleThreadViewItem = (comment: CommentViewNode | null) => {
    if (comment) {
      if (singleThreadViewItem.value) {
        if (singleThreadViewItem.value.id !== comment.id) {
          commentThreadHistory.value.push(singleThreadViewItem.value);
        } else {
          singleThreadViewItem.value = comment;
          return;
        }
      }
    }
    singleThreadViewItem.value = comment;
  };

  const onUpdateCommentThreadHistory = (deletedCommentId: bigint) => {
    commentThreadHistory.value = commentThreadHistory.value.filter(
      (comment) =>
        !containsComment(comment as CommentViewNode, deletedCommentId),
    );
  };

  const containsComment = (
    comment: CommentViewNode,
    commentTargetId: bigint,
  ): boolean => {
    if (comment.id === commentTargetId) return true;

    return comment.children.some((child) =>
      containsComment(child, commentTargetId),
    );
  };

  const onGoBackToPreviousCommentThread = () => {
    singleThreadViewItem.value = commentThreadHistory.value.pop() || null;
  };

  const onSaveCommentIndexInFeed = (fullPath: string, index: number) => {
    savedCommentIndexInFeed.value.set(fullPath, index);
  };

  return {
    comments,
    commentState,
    getCommentState,
    onGoBackToPreviousCommentThread,
    onSaveCommentIndexInFeed,
    onSetComments,
    onSetSingleThreadViewItem,
    onUpdateCommentThreadHistory,
    onUpdateSavedQuery,
    queryFilters,
    savedQuery,
    savedCommentIndexInFeed,
    setCommentState,
    setQueryFilters,
    singleThreadViewItem,
    toggleHidden,
  };
});
