<script lang="ts" setup>
  import ThreadItem from './feed/Item.vue';
  import { useCommentStore, type CommentViewNode } from '@/shared/model';
  import { storeToRefs } from 'pinia';
  import { useI18n } from 'vue-i18n';
  import { onMounted, ref } from 'vue';

  const { t } = useI18n({ useScope: 'global' });

  const commentThread = ref<HTMLDivElement | null>(null);
  const commentStore = useCommentStore();
  const { singleThreadViewItem } = storeToRefs(commentStore);

  onMounted(() => {
    if (commentThread.value) {
      window.scrollTo({
        top: commentThread.value.offsetTop - 100,
        behavior: 'smooth',
      });
    }
  });
</script>

<template>
  <div ref="commentThread" class="flex flex-col gap-6 w-full mt-6 mb-10">
    <base-button
      variant="custom"
      custom-classes="flex gap-2 items-center text-md text-gray-400 tracking-[0.07px]"
      @click="commentStore.onGoBackToPreviousCommentThread"
    >
      <base-icon name="arrow-left" size="size-5" />
      {{ t('comment.backToPrevious') }}
    </base-button>
    <thread-item
      v-if="singleThreadViewItem"
      :comment="singleThreadViewItem as CommentViewNode"
    />
  </div>
</template>
