<script lang="ts" setup>
  import { pluralize } from '@/shared/lib';
  import {
    CompletionImage,
    shareImage,
    useStreakSharingImages,
  } from '@/entities/streak';
  import { computed } from 'vue';

  const props = withDefaults(
    defineProps<{
      days: string;
      multiplier: string;
      points: string;
      username: string;
    }>(),
    {
      days: '0',
      multiplier: '0',
      points: '0',
      username: '',
    },
  );

  const numberOfDays = computed(() => Number(props.days) - 1);
  const { shareImageKey } = useStreakSharingImages(numberOfDays.value);
</script>

<template>
  <div
    class="flex flex-col gap-6 px-4 py-[52px] w-full shadow-[0px_4px_16px_0px_rgba(0,0,0,0.24)] border border-gray-785 bg-gray-990 bg-opacity-50 rounded-xl"
  >
    <div
      class="prose not-prose flex items-center justify-center h-75 mx-auto relative"
    >
      <completion-image :url="shareImage[shareImageKey].url" />
    </div>
    <div>
      <div class="text-center text-2xl font-bold w-full break-words">
        {{ username }} {{ $t('earned') }}
        <span class="text-indigo-300">
          {{ points }}
          {{ pluralize(Number(points), 'point', 'points') }}
        </span>
        {{ $t('and') }}
        <span class="text-indigo-300">
          {{ multiplier }}
          {{ pluralize(Number(multiplier), 'multiplier', 'multipliers') }}
        </span>
      </div>
      <div class="text-center lowercase text-gray-300 font-medium">
        {{ $t('streak.duringAStreakOf') }}
        {{ numberOfDays }}
        {{ $t('days') }}
      </div>
    </div>
  </div>
</template>
