import { useMutation } from '@tanstack/vue-query';
import { enrichApi } from '@/shared/api';

export const useTranslateMutation = () => {
  return useMutation({
    mutationKey: ['translate-post-mutation'],
    mutationFn: (params: enrichApi.TranslateQueryParams) =>
      enrichApi.translate(params),
  });
};
