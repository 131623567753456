import { useFormatHTMLContent } from '@/composables';
import { dscvrIcApi } from '@/shared/api';
import { makeDefaultCreateCommentPayload } from '../lib/make-default-create-comment-payload';
import { useMutation } from '@tanstack/vue-query';
import type { CreateCommentParams } from '../types';

export const useCreateCommentMutation = () => {
  return useMutation({
    mutationKey: ['create-comment-mutation'],
    mutationFn: async (params: CreateCommentParams) => {
      const { storeMediaSources } = useFormatHTMLContent();
      const storedBody = await storeMediaSources(params.body);
      const payload = makeDefaultCreateCommentPayload(
        params.portalId,
        params.parentId,
        storedBody,
      );
      return dscvrIcApi.comment.createComment(payload);
    },
  });
};
