import { useMutation } from '@tanstack/vue-query';
import { dscvrIcApi } from '@/shared/api';

export const useSetNsfwCommentMutation = () => {
  return useMutation({
    mutationKey: ['set-nsfw-comment-mutation'],
    mutationFn: (params: { id: bigint; isNsfw: boolean }) =>
      dscvrIcApi.comment.setNSFWComment(params.id, params.isNsfw),
  });
};
