<script setup lang="ts">
  import { ref, watch, toRef } from 'vue';
  import { fromNow } from '@/utils';
  import CommentForm from '../Form.vue';
  import Actions from '../Actions.vue';
  import { UserTooltip } from '@/entities/user';
  import { BaseTooltip } from '@/shared/ui/base-tooltip';
  import { Badge } from '@/shared/ui/badge';
  import { useI18n } from 'vue-i18n';
  import CommentBody from '../Body.vue';
  import ReplyThread from '../reply/Thread.vue';
  import { useComment } from '../../model/composables/use-comment';
  import CommentFooter from '../Footer.vue';
  import type { CommentViewNode } from '@/shared/model';

  const props = withDefaults(
    defineProps<{
      comment: CommentViewNode;
      index?: number;
      isSuperAdmin?: boolean;
    }>(),
    {
      isSuperAdmin: false,
    },
  );

  const comment = toRef(() => props.comment);
  const {
    createComment,
    deleteComment,
    highlightedCommentId,
    isCommentHighlighted,
    isCommentDeleted,
    isCommenting,
    isEditing,
    isReplying,
    onSetUserRank,
    reactToComment,
    removeComment,
    report,
    setFeedNSFW,
    toggleEdit,
    toggleReply,
    onToggleThread,
    translate,
    updateComment,
  } = useComment(comment);

  const { t } = useI18n({ useScope: 'global' });

  const editForm = ref<HTMLFormElement>();
  const createForm = ref<HTMLFormElement>();

  watch(
    highlightedCommentId,
    (id) => {
      if (id) {
        if (comment.value.children.some((c) => c.id === id)) {
          onToggleThread(comment.value);
        }
      }
    },
    { immediate: true },
  );
</script>

<template>
  <base-tooltip
    interactive
    theme="transparent"
    placement="top-end"
    :arrow="false"
    :offset="[0, -35]"
    append-to-body
    z-index="z-40"
  >
    <div class="flex flex-col gap-2.5">
      <div
        :class="{
          'opacity-50': isCommentDeleted,
        }"
        class="flex flex-col gap-4 w-full"
      >
        <div class="flex w-full gap-2 items-center">
          <user-tooltip
            v-if="!isCommentDeleted"
            :user="comment.owner"
            :portal-info-view="comment.portal"
            :color="comment.owner_color"
            avatar-size="md"
          />
          <div v-else class="text-gray-300 font-semibold">deleted</div>
          <span class="ml-2 text-sm font-medium text-gray-400">
            {{ fromNow(comment.created_at) }}
          </span>
          <badge v-if="isSuperAdmin" spacing="p-1">
            <span class="text-gray-400">{{ comment.lang }}</span>
          </badge>
        </div>
        <div
          :class="isCommentHighlighted ? 'bg-gray-700' : 'bg-gray-950'"
          class="w-full p-4 rounded-b-3xl rounded-tr-3xl"
        >
          <template v-if="isEditing">
            <comment-form
              ref="editForm"
              class="p-4"
              :body="comment.body"
              :editing="isEditing"
              @cancel="isEditing = false"
              @submit="updateComment(comment, $event)"
            />
          </template>
          <template v-else-if="isCommentDeleted">
            <div class="mt-1">
              {{ t('comment.deleted') }}
            </div>
          </template>
          <template v-else>
            <comment-body :comment="comment" />
          </template>
        </div>
        <comment-form
          v-if="isReplying && !isCommentDeleted"
          ref="createForm"
          class="p-4"
          v-model:disabled="isCommenting"
          :replying="isReplying"
          @cancel="isReplying = false"
          @submit="
            createComment(
              {
                portalId: comment.portal.id,
                parentId: comment.id,
                body: $event,
              },
              comment,
            )
          "
        />
      </div>
    </div>
    <template #content="{ isVisible }">
      <actions
        v-if="isVisible && !isCommentDeleted"
        :comment="comment"
        @edit="toggleEdit"
        @delete="deleteComment(comment)"
        @goodbye="onSetUserRank(comment.owner, BigInt(0))"
        @react="reactToComment(comment, $event)"
        @remove="removeComment(comment)"
        @reply="toggleReply"
        @report="report(comment)"
        @set-nsfw="setFeedNSFW(comment.id, $event)"
        @translate="translate(comment)"
      />
    </template>
  </base-tooltip>
  <comment-footer :comment="comment" :index="index" />
  <reply-thread
    :show-replies="!comment.hidden"
    :comments="comment.children"
    :is-super-admin="isSuperAdmin"
  />
</template>
