import { dscvrIcApi } from '@/shared/api';
import { useMutation } from '@tanstack/vue-query';

export const useRemoveCommentMutation = () => {
  return useMutation({
    mutationKey: ['remove-comment-mutation'],
    mutationFn: (params: { id: bigint }) =>
      dscvrIcApi.comment.removeComment(params.id),
  });
};
