<script setup lang="ts">
  import { ref, computed, watch, onMounted, onUnmounted } from 'vue';
  import { storeToRefs } from 'pinia';
  import { BaseTooltip } from '@/shared/ui/base-tooltip';
  import { dscvrApi } from '@/shared/api';
  import { useTipStore } from '@/shared/model';
  import { useGetTippedContentsPollingQuery } from '@/entities/tip/api/use-get-tipped-contents-polling.query';
  import party from 'party-js';
  import anime from 'animejs/lib/anime.es.js';

  const props = withDefaults(
    defineProps<{
      contentId: bigint;
      classes?: string;
    }>(),
    {
      classes: 'flex p-1 text-gray-400 bg-gray-900 hover:text-white rounded-xl',
    },
  );

  const tipDisplay = ref<HTMLElement | null>(null);
  const tipDisplayRefs = ref<Map<string, HTMLImageElement>>(new Map());

  const tipStore = useTipStore();
  const { visibleContentIds } = storeToRefs(tipStore);
  const { data: tippedContentDto } =
    useGetTippedContentsPollingQuery(visibleContentIds);

  const tippedTokens = computed(() => {
    return tippedContentDto.value
      ? tippedContentDto.value[props.contentId.toString()]
      : [];
  });

  const getTooltip = (item: dscvrApi.tip.TipResultItem) => {
    return `${item.totalDisplayAmount} ${item.symbol}`;
  };

  const playSparkles = (tokenAddress: string) => {
    const element = tipDisplayRefs.value.get(tokenAddress);
    if (element) {
      anime({
        targets: element,
        translateY: -15,
        direction: 'alternate',
        easing: 'easeInOutExpo',
        rotate: 720,
        duration: 300,
        scale: 1.25,
        begin: () => {
          if (tipDisplay.value) {
            party.sparkles(tipDisplay.value, {
              count: party.variation.range(5, 10),
              size: party.variation.range(0.5, 3),
              speed: 300,
              lifetime: party.variation.range(0.1, 0.5),
            });
          }
        },
      });
    }
  };

  onMounted(() => {
    tipStore.addVisibleContentId(props.contentId);
  });

  onUnmounted(() => {
    tipStore.removeVisibleContentId(props.contentId);
  });

  //Watch
  watch(tippedTokens, (newVal, oldVal) => {
    if (!newVal || !oldVal) return;
    for (let i = 0; i < newVal.length; i++) {
      const oldItem = oldVal.find(
        (item) => item.tokenAddress === newVal[i].tokenAddress,
      );

      //totalDisplayAmount changed
      if (
        oldItem &&
        oldItem.totalDisplayAmount < newVal[i].totalDisplayAmount
      ) {
        playSparkles(newVal[i].tokenAddress);
      }

      //new token
      if (!oldItem) {
        playSparkles(newVal[i].tokenAddress);
      }
    }
  });
</script>

<template>
  <span
    v-if="tippedTokens && tippedTokens.length > 0"
    ref="tipDisplay"
    :class="classes"
  >
    <base-tooltip
      v-for="item in tippedTokens"
      :key="item.tokenAddress"
      theme="mention"
      :content="getTooltip(item)"
      @click.stop
    >
      <span
        class="ease-in-out transform rounded-full tooltip-parent min-w-5 hover:scale-125 z-1"
        :value="item.tokenAddress"
      >
        <img
          :ref="
            (el) => {
              if (el) tipDisplayRefs.set(item.tokenAddress, el as HTMLImageElement);
            }
          "
          :src="item.icon"
          class="w-5 h-5 rounded-full min-w-5"
        />
      </span>
    </base-tooltip>
  </span>
</template>
