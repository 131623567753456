<script lang="ts" setup>
  import { useTooltip } from '../../model/composables/use-tooltip';
  import type { UserTooltipProps } from '../../types';
  import { Diamond } from '@/shared/ui/diamond';
  import { UserAvatar } from '@/shared/ui/user-avatar';
  import { has } from 'lodash-es';

  const props = withDefaults(defineProps<UserTooltipProps>(), {
    showPfp: true,
    isMention: false,
  });

  const emits = defineEmits<{
    (e: 'clicked-profile'): void;
  }>();

  const { size } = useTooltip(props);
</script>

<template>
  <div class="flex items-center gap-3" @click.stop="emits('clicked-profile')">
    <user-avatar v-if="showPfp" :item="user" :size="size" />
    <div class="flex justify-center items-center gap-2 cursor-pointer">
      <span
        class="font-semibold"
        :class="
          isMention
            ? 'text-indigo-500 hover:text-white'
            : 'text-gray-300 hover:text-indigo-300'
        "
      >
        <template v-if="isMention">@</template>{{ user.username }}
      </span>
      <diamond
        v-if="has(portalInfoView.portal_type, 'Portal') && !isMention"
        :color="color"
        size="size-3"
      />
    </div>
  </div>
</template>
