import type { Ref } from 'vue';
import { dscvrIcApi } from '@/shared/api';
import { useInfiniteQuery } from '@tanstack/vue-query';
import {
  getContentByIdKey,
  hasNextPage,
  useCommentStore,
} from '@/shared/model';
import { commentsKey } from './keys';
import type { ContentQuery } from 'dfx/edge/edge.did';
import { storeToRefs } from 'pinia';

export const useGetCommentsQuery = (query: Ref<ContentQuery>) => {
  const commentStore = useCommentStore();
  const { queryFilters } = storeToRefs(commentStore);
  return useInfiniteQuery({
    queryKey: [
      commentsKey,
      ...getContentByIdKey(query.value.content_id.toString()),
      queryFilters,
    ],
    queryFn: async (ctx) => {
      const updatedQuery = {
        ...query.value,
        thread_start: BigInt(ctx.pageParam),
      };
      const data = await dscvrIcApi.comment.getComments(updatedQuery);
      return 'Ok' in data ? data.Ok : null;
    },
    select: (data) => {
      const pages = data?.pages ?? [];
      const views = pages.flatMap((page) => page?.views ?? []).flat();
      return views;
    },
    refetchInterval: 4000,
    refetchOnMount: false,
    initialPageParam: 0,
    getNextPageParam: (_, allPages, lastPageParam, ___) =>
      hasNextPage(allPages[0]?.total_pages, lastPageParam),
  });
};
