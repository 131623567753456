import { useFormatHTMLContent } from '@/composables';
import { dscvrIcApi } from '@/shared/api';
import { useMutation } from '@tanstack/vue-query';

export const useUpdateCommentMutation = () => {
  return useMutation({
    mutationKey: ['update-comment-mutation'],
    mutationFn: async (params: dscvrIcApi.comment.UpdateCommentParams) => {
      const { storeMediaSources } = useFormatHTMLContent();
      const body = await storeMediaSources(params.body);
      return dscvrIcApi.comment.updateComment({ ...params, body });
    },
  });
};
