<script setup lang="ts">
  import { ref, type Ref } from 'vue';
  import { useToast } from '@/shared/model';
  import { useUser } from '@/entities/user';
  import { useAuth } from '@/entities/auth';
  import { WysiwygEditor } from '@/components/forms/wysiwyg-editor';
  import { useFileUploadDialog } from '@/entities/file-upload';
  import 'tippy.js/dist/tippy.css';
  import 'tippy.js/dist/svg-arrow.css';

  const props = withDefaults(
    defineProps<{
      body?: string;
      editing?: boolean;
      replying?: boolean;
      autofocus?: boolean;
      disabled?: boolean;
    }>(),
    {
      body: '',
      editing: false,
      replying: false,
      autofocus: false,
      disabled: false,
    },
  );

  const emits = defineEmits<{
    (e: 'update:disabled', value: boolean): void;
    (e: 'submit', body: string): void;
    (e: 'cancel'): void;
  }>();

  const { showLoginSignUpDialog } = useAuth();
  const { currentUser } = useUser();
  const { openEditorMediaEmbedDialog, closeDialog } = useFileUploadDialog();
  const commentInput: Ref<InstanceType<typeof WysiwygEditor> | null> =
    ref(null);
  const { showToast } = useToast();
  const modelValue = ref(props.body);

  const reset = () => {
    commentInput.value?.reset();
  };

  const onSubmit = () => {
    if (!currentUser.value) {
      showLoginSignUpDialog();
    } else if (!commentInput.value?.isEmpty) {
      emits('submit', modelValue.value);
      reset();
    } else {
      showToast({
        type: 'error',
        title: 'Please enter a comment',
        durationSeconds: 5,
      });
    }
  };

  const handleMediaUpload = () => {
    openEditorMediaEmbedDialog(
      (files) => commentInput.value?.uploadFiles(files),
      (src) => commentInput.value?.pasteImage(src),
    );
  };

  defineExpose({
    reset,
    isFocused: commentInput.value?.isFocused,
  });
</script>

<template>
  <div class="flex flex-col md:flex-row w-full bg-gray-990 rounded-xl">
    <div class="flex items-center overflow-hidden rounded-lg w-full">
      <wysiwyg-editor
        ref="commentInput"
        context="comment"
        placeholder="Leave a comment"
        :autofocus="autofocus"
        v-model="modelValue"
        @media-inserted="closeDialog"
        @error="showToast({ type: 'error', title: $event, durationSeconds: 5 })"
      />
    </div>
    <div class="flex gap-4 mt-2 items-start justify-between">
      <base-button
        variant="tertiary"
        additional-classes="mt-3"
        @click="handleMediaUpload"
      >
        <base-icon name="image-upload" size="size-5" class="text-gray-500" />
      </base-button>
      <base-button
        v-if="replying || editing"
        variant="tertiary"
        size="small"
        @click="emits('cancel')"
      >
        <span> Cancel </span>
      </base-button>
      <base-button
        variant="primary"
        size="small"
        :disabled="disabled"
        @click="onSubmit"
      >
        <span>
          {{ !editing ? 'Post' : 'Update' }}
        </span>
      </base-button>
    </div>
  </div>
</template>
