<script lang="ts" setup>
  import { computed, toRef } from 'vue';
  import { fromNow } from '@/utils';
  import CommentBody from '../Body.vue';
  import { UserAvatar } from '@/shared/ui/user-avatar';
  import { UserTooltip } from '@/entities/user';
  import { Badge } from '@/shared/ui/badge';
  import { BaseTooltip } from '@/shared/ui/base-tooltip';
  import { useComment } from '../../model/composables/use-comment';
  import Actions from '../Actions.vue';
  import CommentForm from '../Form.vue';
  import { useI18n } from 'vue-i18n';
  import CommentFooter from '../Footer.vue';
  import type { CommentViewNode } from '@/shared/model';

  const props = withDefaults(
    defineProps<{
      comment: CommentViewNode;
      isSuperAdmin: boolean;
      isFirstItem?: boolean;
      isLastItem?: boolean;
    }>(),
    {
      isFirstItem: false,
      isLastItem: false,
    },
  );

  const comment = toRef(() => props.comment);

  const { t } = useI18n({ useScope: 'global' });
  const {
    createComment,
    deleteComment,
    isCommentHighlighted,
    isCommentDeleted,
    isCommenting,
    isEditing,
    isReplying,
    onSetUserRank,
    reactToComment,
    removeComment,
    report,
    setFeedNSFW,
    toggleEdit,
    toggleReply,
    translate,
    updateComment,
  } = useComment(comment);
</script>

<template>
  <base-tooltip
    interactive
    theme="transparent"
    placement="top-end"
    :arrow="false"
    :offset="[0, -35]"
    append-to-body
    z-index="z-40"
    class="reply-item"
  >
    <div
      class="flex gap-4 px-2 z-1"
      :class="{
        'opacity-50': isCommentDeleted,
      }"
    >
      <div class="relative flex flex-col items-center">
        <div v-if="isFirstItem" class="absolute -top-4 h-4 w-px bg-gray-785" />
        <div class="h-14 py-3 bg-gray-975">
          <user-avatar
            v-if="!isCommentDeleted"
            :item="comment.owner"
            size="size-8"
          />
          <div v-else class="size-8 bg-gray-700 rounded-full" />
        </div>
        <div v-if="!isLastItem" class="h-full w-px bg-gray-785" />
      </div>

      <div
        :class="{
          'bg-gray-700': isCommentHighlighted,
        }"
        class="flex flex-col w-full gap-2 py-3 px-4 rounded-xl hover:bg-[#181c26]"
      >
        <div class="flex items-center gap-2">
          <user-tooltip
            :user="comment.owner"
            :portal-info-view="comment.portal"
            :color="comment.owner_color"
            :show-pfp="false"
            class="text-sm"
          />
          <span class="ml-2 text-sm font-medium text-gray-400">
            {{ fromNow(comment.created_at) }}
          </span>
          <badge v-if="isSuperAdmin" spacing="p-1">
            <span class="text-gray-400">{{ comment.lang }}</span>
          </badge>
        </div>
        <div>
          <template v-if="isEditing">
            <comment-form
              ref="editForm"
              v-model:disabled="isCommenting"
              :body="comment.body"
              :editing="isEditing"
              class="p-4"
              @cancel="isEditing = false"
              @submit="updateComment(comment, $event)"
            />
          </template>
          <template v-else-if="isCommentDeleted">
            <div class="mt-1">
              {{ t('comment.deleted') }}
            </div>
          </template>
          <template v-else>
            <comment-body :comment="comment" />
          </template>
        </div>
        <comment-form
          v-if="isReplying && !isCommentDeleted"
          ref="createForm"
          class="p-4"
          :replying="isReplying"
          @cancel="isReplying = false"
          @submit="
            createComment(
              {
                portalId: comment.portal.id,
                parentId: comment.id,
                body: $event,
              },
              comment,
            )
          "
        />
        <comment-footer :comment="comment" is-child-thread />
      </div>
    </div>
    <template #content="{ isVisible }">
      <actions
        v-if="isVisible && !isCommentDeleted"
        :comment="comment"
        @edit="toggleEdit"
        @delete="deleteComment(comment)"
        @goodbye="onSetUserRank(comment.owner, BigInt(0))"
        @react="reactToComment(comment, $event)"
        @remove="removeComment(comment)"
        @reply="toggleReply"
        @report="report(comment)"
        @set-nsfw="setFeedNSFW(comment.id, $event)"
        @translate="translate(comment)"
      />
    </template>
  </base-tooltip>
</template>
