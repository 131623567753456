<script setup lang="ts">
  import { ref, watch, type Ref } from 'vue';
  import { useBreakpoint, useDialog } from '@/shared/model';
  import { PORTAL_CONTENT } from '@/common';
  import { Drawer } from '@/shared/ui/drawer';
  import { BaseTooltip } from '@/shared/ui/base-tooltip';
  import type { PortalView } from 'dfx/edge/edge.did';
  import TooltipContent from './TooltipContent.vue';

  defineProps<{
    portalView: PortalView;
  }>();

  const emits = defineEmits<{
    (e: 'share', portalView: PortalView): void;
  }>();

  const isDrawerOpen = ref(false);
  const portalTooltipRef: Ref<InstanceType<typeof BaseTooltip> | undefined> =
    ref();
  const { isSmallerThanSm } = useBreakpoint();
  const { dialogProps } = useDialog();

  const openMobileDrawer = () => {
    if (isSmallerThanSm.value) {
      isDrawerOpen.value = true;
    }
  };

  watch(
    () => dialogProps.value.opened,
    (open) => {
      if (open) {
        portalTooltipRef.value?.hide();
        isDrawerOpen.value = false;
      }
    },
  );
</script>

<template>
  <base-tooltip
    ref="portalTooltipRef"
    placement="bottom-start"
    theme="transparent"
    interactive
    :arrow="false"
    append-to-body
    :offset="[0, 10]"
    :max-width="420"
    :delay="200"
  >
    <template #default>
      <slot :open-mobile-drawer="openMobileDrawer">
        <base-button
          variant="custom"
          custom-classes="text-left truncate text-lg font-bold hover:underline"
          :to="
            isSmallerThanSm
              ? undefined
              : {
                  name: PORTAL_CONTENT,
                  params: { slug: portalView.slug },
                }
          "
          @click="openMobileDrawer()"
        >
          {{ portalView.name }}
        </base-button>
      </slot>
    </template>
    <template #content="{ isVisible }">
      <tooltip-content
        v-if="!isSmallerThanSm && isVisible"
        :portal-view="portalView"
        @share="emits('share', portalView)"
      />
    </template>
  </base-tooltip>
  <drawer
    v-if="isSmallerThanSm && isDrawerOpen"
    direction="bottom"
    custom-classes="w-full max-h-full mt-2 overflow-y-auto thin-scrollbar shadow-lg bg-gray-950 rounded-t-md focus:outline-none"
    :show-header="false"
    v-model:opened="isDrawerOpen"
  >
    <tooltip-content
      is-drawer
      :portal-view="portalView"
      @share="$emit('share', portalView)"
    />
  </drawer>
</template>
