<script setup lang="ts">
  import { ref } from 'vue';
  import { onClickOutside } from '@vueuse/core';
  import type { ContentReaction } from 'dfx/edge/edge.did';
  import {
    reactions,
    reactionTypeToIconName,
    type ReactionType,
  } from '@/entities/reaction';
  import { computed } from 'vue';
  import type { IconName } from '@/shared/ui/base-icon';

  const props = defineProps<{
    contentReaction?: ContentReaction;
    reactionType?: ReactionType;
    reactionEmoji: IconName | null;
    removeDislike?: boolean;
  }>();

  const emit = defineEmits<{
    (e: 'reacted', reaction?: ReactionType): void;
  }>();

  const form = ref<HTMLElement | null>(null);
  const reactionList = computed(() => {
    if (props.removeDislike) {
      return reactions.filter((r) => r !== 'dislike');
    }
    return reactions;
  });

  const expanded = ref(false);

  const selectReaction = (selectedReactionType?: ReactionType) => {
    expanded.value = false;
    if (!selectedReactionType || selectedReactionType === props.reactionType) {
      emit('reacted');
    } else {
      emit('reacted', selectedReactionType);
    }
  };

  const toggleExpanded = () => {
    expanded.value = !expanded.value;
  };

  onClickOutside(form, () => {
    expanded.value = false;
  });

  defineExpose({
    toggleExpanded,
  });
</script>

<template>
  <form class="flex h-8" ref="form">
    <div
      class="relative bg-gray-950 py-6 rounded-xl border border-gray-785 border-opacity-50"
    >
      <div class="h-full rounded-r-xl flex items-center justify-center z-1">
        <ul
          class="flex text-md gap-4 px-3 animate-fade-right animate-duration-200"
        >
          <li v-for="reactionItem of reactionList" :key="reactionItem">
            <a
              class="cursor-pointer"
              @click.stop="
                selectReaction(
                  reactionItem !== reactionType ? reactionItem : undefined,
                )
              "
            >
              <div
                :class="
                  reactionItem !== reactionType
                    ? 'transition-all duration-200 hover:scale-[200%] hover:drop-shadow-[0_8px_16px_rgba(0,0,0,0.4)] relative z-1 hover:z-10'
                    : ''
                "
              >
                <base-icon
                  :name="reactionTypeToIconName(reactionItem)"
                  :class="reactionItem === reactionType ? 'opacity-30' : ''"
                  class="relative -left-0.5"
                  size="w-6 h-6"
                />
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </form>
</template>
