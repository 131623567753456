<script lang="ts" setup>
  import { toRef } from 'vue';
  import type { CommentViewNode } from '@/shared/model';
  import { useEnrichUrlQuery } from '@/entities/comment';
  import { DynamicContent } from '@/components/dynamic-content';
  import { isValidUrl, isImageUrl, sanitizeSettings } from '@/utils';
  import sanitizeHtml from 'sanitize-html';
  import { stripHTMLPrefix } from '@/shared/lib';

  const props = defineProps<{
    comment: CommentViewNode;
  }>();

  const { data: enrichedComment } = useEnrichUrlQuery(
    toRef(() => props.comment),
  );

  const hasRainbowRole = () => {
    if (props.comment.owner_roles) {
      return props.comment.owner_roles.includes(1877342n);
    }

    return false;
  };

  const isImage = (url: string) => {
    return isValidUrl(url) && isImageUrl(url);
  };
</script>

<template>
  <div v-if="!isImage(comment.body)">
    <div
      :class="{
        'rainbow-text': hasRainbowRole(),
      }"
      class="break-words content-body"
    >
      <dynamic-content
        :body="comment.translatedBody ?? stripHTMLPrefix(comment.body)"
        :portal-info-view="comment.portal"
      />
    </div>

    <div
      v-if="enrichedComment"
      class="mt-1"
      v-html="sanitizeHtml(enrichedComment.html, sanitizeSettings)"
    />
  </div>

  <div v-else>
    <a :href="comment.body" target="_blank">
      <img class="object-fill w-48" :src="comment.body" />
    </a>
  </div>
</template>
