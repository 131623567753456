import type { CreateContent } from 'dfx/edge/edge.did';

export const makeDefaultCreateCommentPayload = (
  portalId: bigint,
  parentId: bigint,
  body: string,
): CreateContent => {
  return {
    content_type: 'comment',
    portal_id: [portalId],
    parent_id: [parentId],
    body,
    title: '',
    url: '',
    lang: '',
    icon_url: '',
    is_nsfw: false,
    poll: [],
    tags: [],
    disable_comments: [],
  };
};
