<script setup lang="ts">
  import { onMounted, ref } from 'vue';
  import { useWalletPairing } from '@/entities/wallets';
  import { trackEvent } from '@/utils';

  const props = withDefaults(
    defineProps<{
      feedIndex: number;
    }>(),
    {
      feedIndex: 0,
    },
  );

  const { fetchPairedWallets } = useWalletPairing();

  const isHypeLabReady = ref(false);

  const onHypeReady = (event: Event) => {
    trackEvent('hype_lab', 'view', props.feedIndex.toString());
    isHypeLabReady.value = true;
  };

  onMounted(() => {
    //eslint-disable-next-line
    HypeLab.initialize({
      environment: 'production',
      propertySlug: '63dd197fcd',
    });

    fetchPairedWallets().then((wallets) => {
      if (wallets.data && wallets.data.length > 0) {
        let walletsFound = wallets.data.map((wallet) => {
          return wallet.address;
        });
        if (walletsFound.length > 0) {
          //eslint-disable-next-line
          HypeLab.setWalletAddresses(walletsFound);
          trackEvent('hype_lab', 'wallets', props.feedIndex.toString());
        }
      }
    });
  });
</script>

<template>
  <div
    class="flex justify-center rounded-xl border cursor-pointer overflow-hidden border-gray-40 border-opacity-70 bg-gray-950"
    :class="{ 'mb-4 p-2': isHypeLabReady, invisible: !isHypeLabReady }"
  >
    <hype-banner @ready="onHypeReady" placement="abe097e372" />
  </div>
</template>
