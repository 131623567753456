<script setup lang="ts">
  import { computed, onBeforeMount, toRef } from 'vue';
  import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';
  import {
    parseSocietyRsErrorType,
    getH1Matches,
    fetchMedia,
  } from '@/shared/lib';
  import { trackEvent } from '@/utils';
  import { Empty } from '@/shared/ui/empty';
  import { Post } from '@/features/post';
  import { useMetaPost, useGetContentByIdQuery } from '@/entities/post';
  import { useFeed } from '@/widgets/feed';
  import { CommentWrapper } from '@/features/comment';

  const props = defineProps<{
    id: string;
    title?: string;
  }>();

  const contentId = toRef(props, 'id');

  const { data, isLoading } = useGetContentByIdQuery(contentId);

  const {
    onDeleteContent,
    onEditContent,
    onGoodbye,
    onPinContent,
    onPinContentToProfile,
    onPollVote,
    onRemoveContent,
    onSetNsfw,
    onSetRank,
    onTranslateContent,
  } = useFeed();

  const currentPost = computed(() => data.value?.result?.[0]);

  const isUnauthorizedPost = computed(() => {
    return (
      data.value &&
      parseSocietyRsErrorType(data.value.error_code) === 'Unauthorized'
    );
  });

  const hasH1TagInBody = computed(() => {
    if (!currentPost.value?.body) return;
    return getH1Matches(currentPost.value.body).length > 0;
  });

  onBeforeMount(() => {
    trackEvent('post view', 'details page', 'default');
  });

  const { ogTitle } = useMetaPost(currentPost);
</script>

<template>
  <section class="content">
    <div class="w-full">
      <template v-if="currentPost">
        <h1 class="hidden" v-if="!currentPost.title && !hasH1TagInBody">
          {{ ogTitle }}
        </h1>
        <post
          class="bg-gray-950"
          :post="currentPost"
          @translate-content="onTranslateContent"
          @delete-content="onDeleteContent"
          @remove-content="onRemoveContent"
          @edit-content="onEditContent"
          @pin-content="onPinContent"
          @pin-content-to-profile="onPinContentToProfile"
          @set-nsfw="onSetNsfw"
          @goodbye="onGoodbye"
          @set-rank="onSetRank"
          @on-poll-vote="onPollVote"
        />
        <comment-wrapper :content="currentPost" />
      </template>
      <empty v-else-if="isUnauthorizedPost">
        <template #image>
          <img
            :src="
              fetchMedia(`${DSCVR_STATIC_ASSETS_CDN_URL}/post/closed-jar.gif`)
            "
            alt="Unauthorized"
            class="object-contain size-48"
          />
        </template>
        <template #title>
          {{ $t('postFeature.postRestricted') }}
        </template>
      </empty>
      <empty v-else-if="!isLoading">
        <template #title>
          {{ $t('postFeature.postDoNotExist') }}
        </template>
        <template #description>
          {{ $t('postFeature.originalPostDoNotExist') }}
        </template>
      </empty>
    </div>
  </section>
</template>
