import { useQuery } from '@tanstack/vue-query';
import { contentKey, getContentByIdKey } from '@/shared/model';
import { dscvrIcApi } from '@/shared/api';
import type { Ref } from 'vue';

export const useGetContentByIdQuery = (contentId: Ref<string>) => {
  return useQuery({
    queryKey: [contentKey, ...getContentByIdKey(contentId.value)],
    queryFn: () => dscvrIcApi.post.getContentById(BigInt(contentId.value)),
  });
};
