<script setup lang="ts">
  import { ref, computed, nextTick, watchEffect, watch } from 'vue';
  import ThreadItem from './Item.vue';
  import { useWindowVirtualizer } from '@tanstack/vue-virtual';
  import { useUserRole } from '@/entities/user';
  import { useUser } from '@/entities/user';
  import type { CommentViewNode } from '@/shared/model';
  import { useCommentStore } from '@/shared/model';
  import { storeToRefs } from 'pinia';
  import { useRoute } from 'vue-router';
  import { useComment } from '../../model/composables/use-comment';

  const MIN_ITEM_SIZE = 265;

  const props = defineProps<{
    comments: CommentViewNode[];
  }>();

  const emits = defineEmits<{
    (e: 'hide-comment-children', id: bigint): void;
    (e: 'fetch-next-page'): void;
  }>();

  const route = useRoute();
  const commentStore = useCommentStore();
  const { savedCommentIndexInFeed } = storeToRefs(commentStore);
  const { isAdmin } = useUserRole();
  const { currentUser } = useUser();
  const { highlightedCommentId } = useComment();

  const commentListRef = ref<HTMLDivElement | null>(null);
  const isSuperAdmin = computed(() =>
    Boolean(currentUser.value && isAdmin.value),
  );
  const virtualizerOptions = computed(() => ({
    count: props.comments.length,
    estimateSize: () => MIN_ITEM_SIZE,
    scrollMargin: commentListRef.value?.offsetTop ?? 0,
    paddingEnd: 250,
    overscan: 10,
  }));
  const windowVirtualizer = useWindowVirtualizer(virtualizerOptions);
  const virtualRows = computed(() => windowVirtualizer.value.getVirtualItems());
  const totalSize = computed(() => windowVirtualizer.value.getTotalSize());

  const measureElement = (el: HTMLDivElement) => {
    nextTick(() => {
      if (!el) return;
      windowVirtualizer.value.measureElement(el);
      return undefined;
    });
    return undefined;
  };

  watchEffect(() => {
    nextTick(() => {
      const position = savedCommentIndexInFeed.value.get(route.fullPath);
      if (position) {
        windowVirtualizer.value.scrollToIndex(position, {
          align: 'start',
        });
      }
    });
  });

  watchEffect(() => {
    const [lastItem] = [...virtualRows.value].reverse();

    if (!lastItem) {
      return;
    }

    if (lastItem.index >= props.comments.length - 1) {
      emits('fetch-next-page');
    }
  });

  watch(
    highlightedCommentId,
    (id) => {
      if (id) {
        nextTick(() => {
          const index = props.comments.findIndex((comment) => {
            const isInMainThread = comment.id === id;
            const isInChildren = comment.children.some((c) => c.id === id);
            return isInMainThread || isInChildren;
          });
          if (index !== -1) {
            windowVirtualizer.value.scrollToIndex(index, {
              align: 'center',
              behavior: 'smooth',
            });
          }
        });
      }
    },
    { immediate: true },
  );
</script>

<template>
  <div ref="commentListRef">
    <div
      class="relative w-full"
      :style="{
        height: `${totalSize}px`,
      }"
    >
      <div
        class="absolute top-0 left-0 w-full"
        :style="{
          transform: `translateY(${
            virtualRows[0]?.start - windowVirtualizer.options.scrollMargin
          }px)`,
        }"
      >
        <div
          v-for="virtualRow in virtualRows"
          :key="virtualRow.key"
          :data-index="virtualRow.index"
          :ref="measureElement"
          class="mb-4 cursor-pointer"
        >
          <thread-item
            :index="virtualRow.index"
            :comment="comments[virtualRow.index]"
            :is-super-admin="isSuperAdmin"
          />
        </div>
      </div>
    </div>
  </div>
</template>
