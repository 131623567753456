<script setup lang="ts">
  import { onMounted, ref } from 'vue';
  import { useWalletPairing } from '@/entities/wallets';
  import { trackEvent } from '@/utils';

  const props = withDefaults(
    defineProps<{
      feedIndex: number;
    }>(),
    {
      feedIndex: 0,
    },
  );

  const { fetchPairedWallets } = useWalletPairing();

  const ctaTextDiv = ref<HTMLDivElement | null>(null);
  const isHypeLabReady = ref(false);

  const onHypeReady = (event: Event) => {
    trackEvent('hype_lab', 'view', props.feedIndex.toString());
    isHypeLabReady.value = true;
  };

  onMounted(() => {
    //eslint-disable-next-line
    HypeLab.initialize({
      environment: 'production',
      propertySlug: '63dd197fcd',
    });

    fetchPairedWallets().then((wallets) => {
      if (wallets.data && wallets.data.length > 0) {
        let walletsFound = wallets.data.map((wallet) => {
          return wallet.address;
        });
        if (walletsFound.length > 0) {
          //eslint-disable-next-line
          HypeLab.setWalletAddresses(walletsFound);
          trackEvent('hype_lab', 'wallets', props.feedIndex.toString());
        }
      }
    });

    if (ctaTextDiv.value) {
      ctaTextDiv.value.addEventListener('click', () => {
        trackEvent('hype_lab', 'click', props.feedIndex.toString());
      });
    }
  });
</script>

<template>
  <hype-native @ready="onHypeReady" placement="88998457e9">
    <div class="pt-4 relative" v-if="isHypeLabReady">
      <div
        class="flex flex-col w-full rounded-xl border cursor-pointer overflow-hidden border-gray-40 border-opacity-70 bg-gray-950"
      >
        <div class="flex flex-col gap-2 pt-4 px-4 sm:pt-5 sm:px-5">
          <div class="relative flex items-center">
            <div class="flex-shrink-0">
              <img data-ref="icon" class="h-10 w-10 rounded-full mr-3" />
            </div>
            <div class="min-w-0 flex-1">
              <span class="absolute inset-0" aria-hidden="true"></span>
              <p class="font-medium text-white">
                @<span data-ref="advertiser"></span>
                <span class="text-gray-400"> - Promoted</span>
              </p>
              <p data-ref="displayUrl" class="text-emerald-300 text-sm"></p>
            </div>
          </div>
          <div data-ref="body" class="mt-2 text-white"></div>
          <div class="body-row text-left">
            <div data-ref="headline" class="mt-3 text-white"></div>

            <div class="my-3">
              <a data-ref="ctaLink" href="/" target="_blank" rel="noreferrer">
                <div data-ref="mediaContent" class="mediaContent h-fit"></div>
                <div
                  ref="ctaTextDiv"
                  data-ref="ctaText"
                  class="rounded-full bg-emerald-300 px-10 py-2 text-black font-bold mt-5 text-center"
                ></div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </hype-native>
</template>
