import { dscvrIcApi } from '@/shared/api';
import { useMutation } from '@tanstack/vue-query';

export const useDeleteCommentMutation = () => {
  return useMutation({
    mutationKey: ['delete-comment-mutation'],
    mutationFn: (params: { id: bigint }) =>
      dscvrIcApi.comment.deleteComment(params.id),
  });
};
