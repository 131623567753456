import { computed, type Ref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { enrichApi } from '@/shared/api';
import { isValidUrl } from '@/utils';
import type { ContentTreeView } from 'dfx/edge/edge.did';

export const useEnrichUrlQuery = (comment: Ref<ContentTreeView>) => {
  const commentId = computed(() => comment.value.id.toString());
  const enabled = computed(() => isValidUrl(comment.value.body));
  return useQuery({
    queryKey: ['enrich-url', commentId],
    queryFn: () => enrichApi.enrichUrl(comment.value.body),
    enabled,
  });
};
