import { actor } from '../base';
import { lang } from '@/utils/lang';
import type {
  CreateContent,
  ContentQuery,
  ContentReaction,
  ActionResultContent,
  ContentTreeViewPageResult,
} from 'dfx/edge/edge.did';
import type { UpdateCommentParams } from './types';
export type { UpdateCommentParams };

export const getComments = (query: ContentQuery) => {
  return actor()
    .get_content_children_page(query)
    .then((res) => res) as Promise<ContentTreeViewPageResult>;
};

export const createComment = async (payload: CreateContent) => {
  payload.lang = await lang.getContentLang();
  return actor()
    .create_content(payload)
    .then((res) => res) as Promise<ActionResultContent>;
};

export const deleteComment = (id: bigint) => {
  return actor().delete_content(id) as Promise<ActionResultContent>;
};

export const removeComment = (id: bigint) => {
  return actor().remove_content(id) as Promise<ActionResultContent>;
};

export const updateComment = (params: UpdateCommentParams) => {
  return actor().update_content(
    params.id,
    params.body,
    [] /* tags */,
    [] /* disable comments */,
    [] /* nsfw */,
    [] /* poll options */,
  ) as Promise<ActionResultContent>;
};

export const reactToComment = async (
  id: bigint,
  reaction?: ContentReaction,
) => {
  const contentReaction = reaction ? [reaction] : [];
  return actor().react_to_content(
    id,
    contentReaction,
  ) as Promise<ActionResultContent>;
};

export const setNSFWComment = (id: bigint, value: boolean) => {
  if (!actor) {
    throw new Error('Actor is not defined');
  }
  return actor().content_nsfw_set(id, value) as Promise<ActionResultContent>;
};
