<script lang="ts" setup>
  import { toRef } from 'vue';
  import { useComment } from '../model/composables/use-comment';
  import { pluralize } from '@/shared/lib';
  import { UserAvatar } from '@/shared/ui/user-avatar';
  import { Avatar } from '@/shared/ui/avatar';
  import { ReactionSummary } from '@/entities/reaction';
  import type { UserView } from 'dfx/edge/edge.did';
  import type { CommentViewNode } from '@/shared/model';
  import { useRoute } from 'vue-router';
  import { TipDisplay } from '../../post';

  const props = withDefaults(
    defineProps<{
      comment: CommentViewNode;
      index?: number;
      isChildThread?: boolean;
    }>(),
    {
      isChildThread: false,
    },
  );
  const route = useRoute();
  const comment = toRef(() => props.comment);
  const { repliesCount, repliesAvatar, onToggleThread, onSaveCommentIndex } =
    useComment(comment);

  const onClickToggleThread = () => {
    if (props.index) {
      onSaveCommentIndex(route.fullPath, props.index);
    }
    onToggleThread(comment.value, props.isChildThread);
  };
</script>

<template>
  <div class="flex flex-col gap-4 mt-4">
    <div
      class="flex w-full gap-2"
      :class="repliesCount === 0 ? 'justify-end' : 'justify-between'"
    >
      <base-button
        v-if="repliesCount > 0"
        variant="custom"
        custom-classes="flex py-2 px-3.5 gap-2 items-center hover:bg-black hover:bg-opacity-24 hover:rounded-lg group/reply"
        :class="{
          'bg-black bg-opacity-24 rounded-lg': !comment.hidden,
        }"
        @click="onClickToggleThread"
      >
        <avatar :avatars="repliesAvatar" reverse>
          <template #default="{ avatars }">
            <user-avatar
              v-for="friend in avatars as UserView[]"
              :key="friend.id.toText()"
              :item="friend"
              :username="friend.username"
              size="size-4"
              class="-ml-1.5"
            />
          </template>
        </avatar>
        <div
          class="text-xs text-indigo-300 uppercase tracking-[0.55px] font-semibold"
        >
          {{ repliesCount }}
          {{ pluralize(repliesCount, 'reply', 'replies') }}
        </div>
        <base-icon
          v-if="!isChildThread"
          name="chevron-down"
          size="size-4"
          class="transform transition-all duration-300 group-hover/reply:block"
          :class="{
            'block rotate-180': !comment.hidden,
            hidden: comment.hidden,
          }"
        />
      </base-button>
      <div class="flex items-center gap-4">
        <tip-display
          :content-id="comment.id"
          classes="flex items-center justify-center rounded-lg bg-gray-400 bg-opacity-[0.04] px-2 text-xxs min-h-[38px]"
        />
        <div
          v-if="comment.reaction_counts.length > 0"
          class="rounded-lg bg-gray-400 bg-opacity-[0.04] px-2 text-xxs"
        >
          <reaction-summary :reaction-counts="comment.reaction_counts" />
        </div>
      </div>
    </div>
  </div>
</template>
