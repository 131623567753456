<script lang="ts" setup>
  import type { Principal } from '@dfinity/principal';
  import { useQueryClient } from '@tanstack/vue-query';
  import type { TipAmount, TippableTokenListItem } from '@/shared/model/tip';
  import { computed, ref, watch, toRef } from 'vue';
  import { useI18n } from 'vue-i18n';
  import party from 'party-js';
  import { trackEventMonetize } from '@/utils';
  import { useUser } from '@/entities/user';
  import {
    usePrepareTipTxMutation,
    useGetUserSolanaWalletsQuery,
    useAddTipTxMutation,
    getUserWalletsQueryKey,
    useGetTippableTokensQuery,
  } from '@/entities/tip';
  import { useConnectSolanaWallets, useWalletDialog } from '@/entities/wallets';
  import { useToast } from '@/shared/model';
  import bs58 from 'bs58';
  import { VersionedTransaction } from '@solana/web3.js';
  import { getConnection } from '@/shared/api/sol-api/rpc';
  import { BaseTooltip } from '@/shared/ui/base-tooltip';
  import { Loader } from '@/shared/ui/loader';

  interface TipHash {
    [key: string]: TipAmount[];
  }

  const props = withDefaults(
    defineProps<{
      contentId: bigint;
      userId: Principal | null;
      sizeIcon?: string;
    }>(),
    {
      contentId: () => 0n,
      userId: null,
      sizeIcon: 'size-5',
    },
  );

  const { data: tippableTokens } = useGetTippableTokensQuery();
  const rpcConnection = getConnection();

  const { t } = useI18n({ useScope: 'global' });
  const queryClient = useQueryClient();
  const { showToast } = useToast();

  const userId = toRef(props, 'userId');

  const { data: contentOwnerWallets } = useGetUserSolanaWalletsQuery(
    userId.value?.toText() as string,
  );

  const { mutateAsync: addTipTxAsyncMutation } = useAddTipTxMutation();
  const { mutateAsync: prepareTipTxAsyncMutation } = usePrepareTipTxMutation();

  const showSecondTooltip = ref(false);
  const tipAmountHash = ref<TipHash>({}); // these are filled from the default tip amounts, but based on the maximum amount of tokens the user has
  const isPreparingWallet = ref(false); // if true, the wallet is being prepared for the tip
  const selectedUserToken = ref<TippableTokenListItem | null>(null); // object, if set, a second popup with the quantities is shown

  const { currentUserPrincipal } = useUser();
  const tipButtonRef = ref();

  const contentUserPrincipalText = computed(() => {
    return props.userId ? props.userId.toText() : props.userId;
  });

  const currentUserPrincipalText = computed(() => {
    return currentUserPrincipal.value
      ? currentUserPrincipal.value.toText()
      : currentUserPrincipal.value;
  });

  const performTip = async (
    tippableTokenId: string,
    amount: number,
    toTipAddress: string,
  ) => {
    const { wallet, selectAndConnect } = useConnectSolanaWallets();
    const { openConnectWalletDialog } = useWalletDialog();
    if (!wallet.value) {
      // ask user to connect wallet if none is connect / selected
      openConnectWalletDialog();
      return { status: 'failed', signature: '', from: '', to: '', amount };
    }

    if (!wallet.value.adapter.connected || !wallet.value.adapter.publicKey) {
      await selectAndConnect(wallet.value.id);
    }

    const from = wallet.value.adapter.publicKey!.toBase58();
    const to = toTipAddress;

    const tipTx = await prepareTipTxAsyncMutation({
      tipId: tippableTokenId,
      from,
      to,
      amount,
    });
    if (tipTx) {
      const txUint8Array = bs58.decode(tipTx.tx);
      const versionedTransaction =
        VersionedTransaction.deserialize(txUint8Array);

      try {
        const signature = await wallet.value.adapter.sendTransaction(
          versionedTransaction,
          rpcConnection,
        );

        return { signature, from, to, amount, status: 'success' };
      } catch (error) {
        console.log('Error sending transaction', error);
        throw error;
      }
    }
    throw new Error('Failed to get tip transaction');
  };

  const getTipAmount = (tipAmount: TipAmount, tokenAddress: string) => {
    if (tokenAddress == 'p4i2hD8sXEDkWnhVDLmWFM5VsjMagtoQypt6D2rRvpY') {
      return tipAmount.usdAmount;
    }
    return tipAmount.usdAmount.toLocaleString(`en-US`, {
      style: 'currency',
      currency: `USD`,
    });
  };

  const selectUserToken = (userToken: TippableTokenListItem) => {
    if (selectedUserToken.value == userToken) return;
    showSecondTooltip.value = true;
    selectedUserToken.value = userToken;
    tipAmountHash.value[userToken.address] = userToken.tipAmounts;
  };

  const tip = async (
    tipAmount: TipAmount,
    selectedUserToken: TippableTokenListItem,
    index: number,
  ) => {
    isPreparingWallet.value = true;
    showToast({
      title: t('tip.preparingTipTitle'),
      description: t('tip.preparingTipDescription'),
      type: 'info',
      durationSeconds: 2,
    });

    if (!contentOwnerWallets.value || !contentOwnerWallets.value.length) {
      isPreparingWallet.value = false;
      showToast({
        title: t('tip.contentOwnerHasNoWalletTitle'),
        description: t('tip.contentOwnerHasNoWalletDescription'),
        type: 'info',
      });
      return;
    }

    const walletToTip =
      contentOwnerWallets.value.find((w) => w.isPrimary) ??
      contentOwnerWallets.value[0];

    try {
      const { signature, from, to, status } = await performTip(
        selectedUserToken.id,
        tipAmount.tokenAmount,
        walletToTip.address,
      ).finally(() => {
        isPreparingWallet.value = false;
      });

      if (!signature) {
        showToast({
          title: t('tip.signatureFailed'),
          description: t('tip.signatureFailed'),
          type: 'error',
        });
        return;
      }

      if (status === 'failed') {
        showToast({
          title: t('tip.connectWallet'),
          description: t('tip.connectWallet'),
          type: 'error',
        });
      }

      trackEventMonetize(
        selectedUserToken.symbol,
        tipAmount.usdAmount,
        props.contentId,
      );

      await addTipTxAsyncMutation({
        tipId: selectedUserToken.id,
        amount: BigInt(tipAmount.tokenAmount).toString() as string,
        toUser: props.userId?.toText() as string,
        fromUser: currentUserPrincipalText.value as string,
        contentId: props.contentId.toString(),
        signature: signature,
        to: to,
        from: from,
      });

      showToast({
        title: t('tip.successTitle'),
        description: t('tip.successDescription'),
        type: 'success',
      });

      try {
        party.confetti(tipButtonRef.value, {
          count: party.variation.range(5 * (index + 1), 10 * (index + 1)),
          spread: 20,
          size: party.variation.range(1, 1.3),
        });
      } catch (e) {
        console.error(e);
      }
    } catch (e) {
      console.error(e);
      showToast({
        title: t('tip.signatureFailed'),
        description: t('tip.signatureFailed'),
        type: 'error',
      });
    }
  };

  watch(
    contentUserPrincipalText,
    async (value) => {
      if (value) {
        queryClient.invalidateQueries({
          queryKey: getUserWalletsQueryKey(
            contentUserPrincipalText.value as string,
          ),
        });
      }
    },
    { immediate: true },
  );
</script>

<template>
  <base-tooltip interactive theme="transparent" arrow="false">
    <div class="flex bg-gray-600 rounded-lg">
      <div
        v-for="tippableToken in tippableTokens"
        :key="tippableToken.id"
        class="relative text-center duration-200 ease-in-out group"
        @click.stop="selectUserToken(tippableToken)"
        @mouseover="selectUserToken(tippableToken)"
      >
        <div
          class="flex justify-center hover:scale-110 hover:font-bold p-3 rounded-lg"
          :class="{
            'bg-gray-700 scale-110': selectedUserToken?.id == tippableToken?.id,
          }"
        >
          <img
            :src="tippableToken.icon"
            alt="Token Icon"
            class="w-10 rounded-full"
          />
        </div>
      </div>
    </div>
    <template #content>
      <template
        v-if="
          selectedUserToken &&
          tipAmountHash[selectedUserToken.address] &&
          tipAmountHash[selectedUserToken.address].length > 0
        "
      >
        <div
          class="relative z-50 flex gap-4 mb-1 bg-gray-600 rounded-lg min-w-max cursor-pointer"
        >
          <div
            class="absolute w-full h-full pb-2 z-100 bg-black bg-opacity-60 flex items-center justify-center rounded-lg"
            v-if="isPreparingWallet"
          >
            <div class="flex items-center">
              <div class="relative text-center text-white w-16 h-16 mt-4">
                <loader variant="rainbow" size="size-6" border-width="border" />
              </div>
            </div>
          </div>
          <div
            v-for="(tipAmount, index) in tipAmountHash[
              selectedUserToken.address
            ]"
            :key="tipAmount.tokenAmount"
            class="p-3 inline text-center duration-300 ease-in-out transform rounded-full hover:scale-110 hover:font-bold"
            @click.stop="tip(tipAmount, selectedUserToken, index)"
          >
            <div class="flex flex-col items-center justify-center mb-1">
              <img
                :src="selectedUserToken.icon"
                alt="Token Icon"
                class="w-10 rounded-full"
              />
            </div>
            <span>{{
              getTipAmount(tipAmount, selectedUserToken.address)
            }}</span>
          </div>
        </div>
      </template>
    </template>
  </base-tooltip>
</template>
