import { computed } from 'vue';

export const useStreakSharingImages = (days: number) => {
  const getShareImageKey = (week: number): number => {
    const baseKey = 7;
    const maxKey = 364;
    const step = 7;

    const range = maxKey - baseKey + step;

    return ((((week - baseKey) % range) + range) % range) + baseKey;
  };

  const shareImageKey = computed(() => getShareImageKey(days));

  return {
    getShareImageKey,
    shareImageKey,
  };
};
