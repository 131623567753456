<script setup lang="ts">
  import { computed, toRef } from 'vue';
  import ActionDropdown from './ActionDropdown.vue';
  import { BaseTooltip } from '@/shared/ui/base-tooltip';
  import { PermissionFlags, hasPermission } from '@/shared/lib';
  import { TipNew } from '@/features/post';
  import { useUser, useUserRole } from '@/entities/user';
  import { useReaction, type ReactionType } from '@/entities/reaction';
  import ReactionForm from './ReactionForm.vue';
  import { useI18n } from 'vue-i18n';
  import { lang } from '@/utils';
  import type { CommentViewNode } from '@/shared/model';

  const props = defineProps<{
    comment: CommentViewNode;
  }>();

  const emits = defineEmits<{
    (e: 'edit'): void;
    (e: 'delete'): void;
    (e: 'react', reactionType?: ReactionType): void;
    (e: 'remove'): void;
    (e: 'translate'): void;
    (e: 'reply'): void;
    (e: 'report'): void;
    (e: 'set-nsfw', value: boolean): void;
    (e: 'goodbye'): void;
  }>();

  const languages = lang.getTranslationLang();
  const { t } = useI18n({ useScope: 'global' });
  const { contentReaction, react, reactionType, reactionEmoji } = useReaction(
    toRef(props, 'comment'),
  );
  const { isAdmin } = useUserRole();
  const { currentUser } = useUser();

  const canDislike = computed(() =>
    hasPermission(props.comment.perm, PermissionFlags.DISLIKE_CONTENT),
  );

  const canEdit = computed(
    () =>
      currentUser.value &&
      props.comment.owner_id.toText() == currentUser.value.id.toText(),
  );

  const onReact = (reactionType?: ReactionType) => {
    react('comment_action', reactionType);
    if (currentUser.value) {
      emits('react', reactionType);
    }
  };
</script>

<template>
  <div
    class="flex items-center gap-3 text-sm p-2 text-gray-400 bg-gray-975 border border-gray-785 border-opacity-50 rounded-xl"
  >
    <base-tooltip
      theme="transparent"
      interactive
      :arrow="false"
      :offset="[0, 30]"
    >
      <base-button
        variant="custom"
        custom-classes="btn-comment-actions group/comment"
      >
        <base-icon
          name="outlined-emoji-plus"
          size="size-4"
          class="fill-gray-800 group-hover/comment:fill-gray-700"
        />
      </base-button>
      <template #content>
        <reaction-form
          :content-reaction="contentReaction"
          :reaction-type="reactionType"
          :reaction-emoji="reactionEmoji"
          :remove-dislike="!canDislike"
          @reacted="onReact"
        />
      </template>
    </base-tooltip>

    <base-tooltip
      v-if="hasPermission(comment.perm, PermissionFlags.CREATE_CONTENT_COMMENT)"
      :content="t('postButtonLabelReply')"
    >
      <base-button
        variant="custom"
        custom-classes="btn-comment-actions group/comment"
        @click="emits('reply')"
      >
        <base-icon
          name="vuesax-linear-message-text"
          size="size-4"
          class="fill-gray-800 group-hover/comment:fill-gray-700"
        />
      </base-button>
    </base-tooltip>

    <base-tooltip
      v-if="currentUser"
      theme="transparent"
      interactive
      :offset="[0, 10]"
    >
      <base-button
        variant="custom"
        custom-classes="btn-comment-actions group/comment"
      >
        <base-icon
          name="outlined-tokens"
          size="size-4"
          class="fill-gray-800 group-hover/comment:fill-gray-700"
        />
      </base-button>
      <template #content>
        <tip-new
          :content-id="comment.id"
          :user-id="comment.owner_id"
          size-icon="size-4"
        />
      </template>
    </base-tooltip>
    <base-tooltip v-if="languages !== '0'" :content="t('translate')">
      <base-button
        variant="custom"
        custom-classes="btn-comment-actions"
        @click="emits('translate')"
      >
        <base-icon name="translate" size="size-4" />
      </base-button>
    </base-tooltip>

    <base-tooltip v-if="isAdmin" content="Goodbye">
      <base-button
        variant="custom"
        custom-classes="btn-comment-actions"
        @click="emits('goodbye')"
      >
        👋
      </base-button>
    </base-tooltip>

    <action-dropdown
      v-if="currentUser"
      :comment="comment"
      :can-edit="canEdit"
      :can-delete="canEdit"
      @edit="emits('edit')"
      @delete="emits('delete')"
      @remove="emits('remove')"
      @report="emits('report')"
      @set-nsfw="emits('set-nsfw', $event)"
    />
  </div>
</template>
