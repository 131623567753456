<script setup lang="ts">
  import { computed, ref, type Ref } from 'vue';
  import RelevantCommentThread from './RelevantCommentThread.vue';
  import CommentShortForm from './CommentShortForm.vue';
  import { useToast } from '@/shared/model';
  import { useUser } from '@/entities/user';
  import { onClickOutside } from '@vueuse/core';
  import type { ActionResultContent, ContentView } from 'dfx/edge/edge.did';
  import { hasPermission, PermissionFlags } from '@/shared/lib';
  import {
    POST_DETAIL_COMMENT_NO_TITLE,
    POST_DETAIL_ID_ONLY,
    POST_DETAIL,
  } from '@/common';
  import { useRoute } from 'vue-router';
  import { useCreateCommentMutation } from '@/entities/comment';
  import { trackEvent } from '@/utils';
  import { useAuth } from '@/entities/auth';

  const props = defineProps<{
    comments: ContentView[];
    parentPost: ContentView;
  }>();

  const emits = defineEmits<{
    (e: 'clicked-comment', comment: ContentView): void;
    (e: 'on-comment', comment: ContentView): void;
  }>();

  const route = useRoute();
  const { currentUser } = useUser();
  const { showLoginSignUpDialog } = useAuth();
  const { showToast } = useToast();
  const { mutate: createComment } = useCreateCommentMutation();
  const commentForm: Ref<InstanceType<typeof CommentShortForm> | null> =
    ref(null);
  const replyToComment = ref<ContentView | null>();
  const wrapper = ref<HTMLElement | null>(null);

  const showShortCommentForm = computed(
    () =>
      hasPermission(
        props.parentPost.perm,
        PermissionFlags.CREATE_CONTENT_COMMENT,
      ) &&
      route.name !== POST_DETAIL &&
      route.name !== POST_DETAIL_COMMENT_NO_TITLE &&
      route.name !== POST_DETAIL_ID_ONLY,
  );
  const placeholder = computed(() => {
    if (replyToComment.value) {
      return `Reply to ${replyToComment.value.owner.username}`;
    }

    return 'Leave a comment';
  });

  const selectCommentToReply = (comment: ContentView) => {
    replyToComment.value = comment;
    if (commentForm.value) {
      commentForm.value.focus();
    }
  };

  const commentSaved = () => {
    const message = replyToComment.value ? 'Reply sent' : 'Comment created';
    commentForm.value?.reset();
    commentForm.value?.showStatusMessage(message);
    replyToComment.value = null;
  };

  const commentBoxSelected = () => {
    if (!currentUser.value) {
      showLoginSignUpDialog('signup');
    }
  };

  const save = (body: string) => {
    if (!currentUser.value) return;
    commentForm.value?.setSaving(true);
    createComment(
      {
        portalId: props.parentPost.portal.id,
        parentId: replyToComment.value?.id || props.parentPost.id,
        body,
      },
      {
        onSuccess: (response: ActionResultContent) => {
          if (response.status === 'happy') {
            trackEvent('comment_action', 'feed_reply');
            if (response.result[0]) {
              emits('on-comment', response.result[0]);
            }
            commentSaved();
          } else {
            showToast({
              type: 'error',
              title: 'There was a problem saving the comment',
              durationSeconds: 5,
            });
            commentForm.value?.focus();
          }
        },
        onError: (error: Error) => {
          showToast({
            type: 'error',
            title: error.message,
            durationSeconds: 5,
          });
          commentForm.value?.focus();
        },
        onSettled: () => {
          commentForm.value?.setSaving(false);
        },
      },
    );
  };

  onClickOutside(wrapper, () => {
    replyToComment.value = null;
  });
</script>

<template>
  <div
    ref="wrapper"
    class="w-full"
    :class="{
      'bg-[#191C27] p-5 pt-8 rounded-b-xl border-t border-gray-700 border-solid border-opacity-70':
        showShortCommentForm || comments.length > 0,
    }"
  >
    <relevant-comment-thread
      v-for="comment in comments"
      :key="comment.id.toString()"
      :comment="comment"
      @reply="selectCommentToReply"
      @clicked-comment="emits('clicked-comment', $event)"
    />
    <comment-short-form
      v-if="showShortCommentForm"
      ref="commentForm"
      class="mt-3"
      :placeholder="placeholder"
      @submit="save"
      @error="showToast({ type: 'error', title: $event, durationSeconds: 5 })"
      @click.stop="commentBoxSelected"
    />
  </div>
</template>
