<template>
  <div class="flex flex-col gap-4 w-full mt-8">
    <div
      class="relative flex w-full bg-blue-gray-900 rounded-2xl border border-opacity-50 border-gray-785 p-6"
    >
      <slot name="form" />
    </div>
    <div class="flex flex-col gap-8 w-full rounded-2xl bg-blue-gray-900 p-6">
      <slot name="feed" />
    </div>
  </div>
</template>
