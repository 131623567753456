import { storeToRefs } from 'pinia';
import { ref, computed, type Ref } from 'vue';
import { useUser } from '@/entities/user';
import { useAuth } from '@/entities/auth';
import {
  useTranslateMutation,
  commentsKey,
  useUpdateCommentMutation,
  useCreateCommentMutation,
  useDeleteCommentMutation,
  useRemoveCommentMutation,
  useSetNsfwCommentMutation,
  type CreateCommentParams,
} from '@/entities/comment';
import { lang } from '@/utils/lang';
import {
  useToast,
  useCommentStore,
  getContentByIdKey,
  type CommentViewNode,
} from '@/shared/model';
import { useQueryClient, type InfiniteData } from '@tanstack/vue-query';
import { useI18n } from 'vue-i18n';
import {
  reactionTypeToContentReaction,
  setReactionCount,
  type ReactionType,
} from '@/entities/reaction';
import { useStore } from 'vuex';
import { ActionTypes } from '@/store';
import { useRoute } from 'vue-router';

export const useComment = (comment?: Ref<CommentViewNode>) => {
  const route = useRoute();
  const store = useStore();
  const queryClient = useQueryClient();
  const commentStore = useCommentStore();
  const { savedQuery, queryFilters, singleThreadViewItem } =
    storeToRefs(commentStore);
  const { currentUser, onSetUserRank } = useUser();
  const { showLoginSignUpDialog } = useAuth();
  const { showToast } = useToast();
  const { t } = useI18n({ useScope: 'global' });

  const { mutateAsync: translateCommentMutationAsync } = useTranslateMutation();
  const { mutate: updateCommentMutation } = useUpdateCommentMutation();
  const { mutate: createCommentMutation } = useCreateCommentMutation();
  const { mutate: deleteCommentMutation } = useDeleteCommentMutation();
  const { mutate: removeCommentMutation } = useRemoveCommentMutation();
  const { mutate: setNsfwCommentMutation } = useSetNsfwCommentMutation();

  const isReplying = ref(false);
  const isEditing = ref(false);
  const isCommenting = ref(false);
  const highlightedCommentId = computed(() => {
    const hash = route.hash
      ? route.hash.substring(1)
      : route.params.comment?.toString();
    return hash ? BigInt(hash) : null;
  });
  const isCommentHighlighted = computed(
    () => highlightedCommentId.value === comment?.value.id,
  );
  const queryKey = computed(() => [
    commentsKey,
    ...getContentByIdKey(savedQuery.value?.content_id.toString() ?? ''),
    queryFilters,
  ]);

  const isCommentDeleted = computed(() => comment?.value.body === '[deleted]');
  const repliesCount = computed(() => comment?.value.children.length ?? 0);
  const repliesAvatar = computed(() =>
    comment?.value.children.slice(0, 3).map((child) => child.owner),
  );

  const updateQueryCache = (
    key: any[],
    updateFn: (
      data: InfiniteData<any | null, number>,
    ) => InfiniteData<any | null, number>,
  ) => {
    queryClient.setQueryData(key, (oldData) =>
      oldData ? updateFn(oldData) : oldData,
    );
  };

  const updateChildInTree = (
    comment: CommentViewNode,
    commentTargetId: bigint,
    partialUpdatedComment: Partial<CommentViewNode>,
  ): CommentViewNode => {
    if (comment.id === commentTargetId) {
      return { ...comment, ...partialUpdatedComment };
    }
    return {
      ...comment,
      children: comment.children.map((child) =>
        updateChildInTree(child, commentTargetId, partialUpdatedComment),
      ),
    };
  };

  const updateChildInTreeForList = (
    comments: CommentViewNode[],
    commentTargetId: bigint,
    partialUpdatedComment: Partial<CommentViewNode>,
  ): CommentViewNode[] => {
    return comments.map((comment) =>
      updateChildInTree(comment, commentTargetId, partialUpdatedComment),
    );
  };

  const addChildToTree = (
    comment: CommentViewNode,
    parentId: bigint,
    child: CommentViewNode,
  ): CommentViewNode => {
    if (comment.id === parentId) {
      return {
        ...comment,
        children: [child, ...comment.children],
      };
    }
    return {
      ...comment,
      children: comment.children.map((childNode) =>
        addChildToTree(childNode, parentId, child),
      ),
    };
  };

  const addChildToTreeForList = (
    comments: CommentViewNode[],
    parentId: bigint,
    child: CommentViewNode,
  ): CommentViewNode[] => {
    return comments.map((comment) => addChildToTree(comment, parentId, child));
  };

  const translate = async (comment: CommentViewNode) => {
    const updateTranslation = (translatedBody?: string) => {
      updateQueryCache(queryKey.value, (oldData) => ({
        ...oldData,
        pages: oldData.pages.map((page) => ({
          ...page,
          views: updateChildInTreeForList(page.views, comment.id, {
            translatedBody,
          }),
        })),
      }));
      if (singleThreadViewItem.value) {
        const updatedSingleThreadItem = updateChildInTree(
          singleThreadViewItem.value as CommentViewNode,
          comment.id,
          { translatedBody },
        );
        commentStore.onSetSingleThreadViewItem(updatedSingleThreadItem);
      }
    };

    if (comment.translatedBody) {
      comment.translatedBody = undefined;
      updateTranslation(undefined);
    } else {
      await translateCommentMutationAsync(
        {
          text: comment.body,
          lang: lang.getTranslationLang(),
        },
        {
          onSuccess: (response) => {
            if (response.data && comment) {
              const translatedText =
                response.data.translations[0].translatedText;
              comment.translatedBody = translatedText;
              updateTranslation(translatedText);
            }
          },
          onError: () => {
            showToast({
              title: t('comment.translationError'),
              type: 'error',
            });
          },
        },
      );
    }
  };

  const toggleReply = () => {
    if (!currentUser.value) {
      showLoginSignUpDialog();
    } else {
      isReplying.value = !isReplying.value;
    }
  };

  const toggleEdit = () => {
    isEditing.value = !isEditing.value;
  };

  const createComment = (
    params: CreateCommentParams,
    parentComment?: CommentViewNode,
  ) => {
    isCommenting.value = true;

    createCommentMutation(params, {
      onSuccess: (data) => {
        if (data.status === 'happy' && data.result.length) {
          const newComment: CommentViewNode = {
            ...data.result[0],
            updated_at: data.result[0].created_at,
            children: [],
            reaction_counts: [],
          };

          updateQueryCache(queryKey.value, (oldData) => ({
            ...oldData,
            pages: oldData.pages.map((page) => ({
              ...page,
              views: parentComment
                ? addChildToTreeForList(
                    page.views,
                    parentComment.id,
                    newComment,
                  )
                : [newComment, ...page.views],
            })),
          }));

          if (parentComment && singleThreadViewItem.value) {
            const updatedSingleThreadItem = addChildToTree(
              singleThreadViewItem.value as CommentViewNode,
              parentComment.id,
              newComment,
            );
            commentStore.onSetSingleThreadViewItem(updatedSingleThreadItem);
          }
          showToast({ title: t('comment.createSuccess'), type: 'success' });
        } else {
          showToast({ title: t('comment.createError'), type: 'error' });
        }
      },
      onError: () => {
        showToast({ title: t('comment.createError'), type: 'error' });
      },
      onSettled: () => {
        isCommenting.value = false;
        isReplying.value = false;
      },
    });
  };

  const updateComment = (comment: CommentViewNode, body: string) => {
    isCommenting.value = true;

    updateCommentMutation(
      { id: comment.id, body },
      {
        onSuccess: () => {
          const updatedComment = { ...comment, body };
          updateQueryCache(queryKey.value, (oldData) => ({
            ...oldData,
            pages: oldData.pages.map((page) => ({
              ...page,
              views: updateChildInTreeForList(
                page.views,
                updatedComment.id,
                updatedComment,
              ),
            })),
          }));
          if (singleThreadViewItem.value) {
            const updatedSingleThreadItem = updateChildInTree(
              singleThreadViewItem.value as CommentViewNode,
              updatedComment.id,
              updatedComment,
            );
            commentStore.onSetSingleThreadViewItem(updatedSingleThreadItem);
          }
          showToast({ title: t('comment.updateSuccess'), type: 'success' });
        },
        onError: () => {
          showToast({ title: t('comment.updateError'), type: 'error' });
        },
        onSettled: () => {
          isCommenting.value = false;
          isEditing.value = false;
        },
      },
    );
  };

  const markChildAsDeleted = (
    views: CommentViewNode[],
    commentId: bigint,
  ): CommentViewNode[] => {
    return views.map((view) => ({
      ...view,
      children: view.children.map((child) => {
        if (child.id === commentId) {
          return {
            ...child,
            title: '[deleted]',
            body: '[deleted]',
          };
        }
        return {
          ...child,
          children: markChildAsDeleted(child.children, commentId),
        };
      }),
    }));
  };

  const removeTopLevelComment = (
    views: CommentViewNode[],
    commentId: bigint,
  ): CommentViewNode[] => {
    return views.filter((view) => view.id !== commentId);
  };

  const deleteComment = (comment: CommentViewNode) => {
    const id = comment.id;

    deleteCommentMutation(
      { id },
      {
        onSuccess: (data) => {
          if (data.status === 'happy') {
            queryClient.setQueryData(
              queryKey.value,
              (oldData: InfiniteData<any | null, number>) => {
                if (!oldData) return oldData;

                const updatedPages = oldData.pages.map((page) => ({
                  ...page,
                  views: page.views.some((view: CommentViewNode) =>
                    view.children.some(
                      (child: CommentViewNode) => child.id === id,
                    ),
                  )
                    ? markChildAsDeleted(page.views, id)
                    : removeTopLevelComment(page.views, id),
                }));

                if (singleThreadViewItem.value) {
                  const updatedSingleThreadItem = updateChildInTree(
                    singleThreadViewItem.value as CommentViewNode,
                    id,
                    { title: '[deleted]', body: '[deleted]' },
                  );
                  commentStore.onSetSingleThreadViewItem(
                    updatedSingleThreadItem,
                  );
                }

                commentStore.onUpdateCommentThreadHistory(id);

                return { ...oldData, pages: updatedPages };
              },
            );

            showToast({
              title: t('comment.deleteSuccess'),
              type: 'success',
            });
          } else {
            showToast({
              title: t('comment.deleteError'),
              type: 'error',
            });
          }
        },
        onError: () => {
          showToast({
            title: t('comment.deleteError'),
            type: 'error',
          });
        },
      },
    );
  };

  const removeComment = (comment: CommentViewNode) => {
    const id = comment.id;

    removeCommentMutation(
      { id },
      {
        onSuccess: (data) => {
          if (data.status === 'happy') {
            queryClient.setQueryData(
              queryKey.value,
              (oldData: InfiniteData<any | null, number>) => {
                if (!oldData) return oldData;

                const updatedPages = oldData.pages.map((page) => ({
                  ...page,
                  views: page.views.some((view: CommentViewNode) =>
                    view.children.some(
                      (child: CommentViewNode) => child.id === id,
                    ),
                  )
                    ? markChildAsDeleted(page.views, id)
                    : removeTopLevelComment(page.views, id),
                }));

                if (singleThreadViewItem.value) {
                  const updatedSingleThreadItem = updateChildInTree(
                    singleThreadViewItem.value as CommentViewNode,
                    id,
                    { title: '[deleted]', body: '[deleted]' },
                  );
                  commentStore.onSetSingleThreadViewItem(
                    updatedSingleThreadItem,
                  );
                }

                return { ...oldData, pages: updatedPages };
              },
            );

            showToast({
              title: t('comment.removeSuccess'),
              type: 'success',
            });
          } else {
            showToast({
              title: t('comment.removeError'),
              type: 'error',
            });
          }
        },
        onError: () => {
          showToast({
            title: t('comment.removeError'),
            type: 'error',
          });
        },
      },
    );
  };

  const reactToComment = (
    comment: CommentViewNode,
    reactionType?: ReactionType,
  ) => {
    if (!currentUser.value) {
      showLoginSignUpDialog();
      return;
    }
    const newCommentReaction = reactionTypeToContentReaction(reactionType);
    const newReactionCount = setReactionCount(
      comment.reaction_counts,
      newCommentReaction,
      comment.is_reactor[0],
    );
    const updatedComment = { ...comment, reaction_counts: newReactionCount };
    updateQueryCache(queryKey.value, (oldData) => ({
      ...oldData,
      pages: oldData.pages.map((page) => ({
        ...page,
        views: updateChildInTreeForList(
          page.views,
          updatedComment.id,
          updatedComment,
        ),
      })),
    }));
    if (singleThreadViewItem.value) {
      const updatedSingleThreadItem = updateChildInTree(
        singleThreadViewItem.value as CommentViewNode,
        updatedComment.id,
        updatedComment,
      );
      commentStore.onSetSingleThreadViewItem(updatedSingleThreadItem);
    }
  };

  const report = (comment: CommentViewNode) => {
    store.dispatch(ActionTypes.SHOW_REPORT_MODAL, {
      value: true,
      contentId: comment.id,
    });
  };

  const setFeedNSFW = (id: bigint, value: boolean) => {
    setNsfwCommentMutation(
      { id, isNsfw: value },
      {
        onSuccess: () => {
          showToast({
            title: t('comment.setNSFWSuccess'),
            type: 'success',
          });
        },
      },
    );
  };

  const onToggleThread = (comment: CommentViewNode, isChildThread = false) => {
    commentStore.toggleHidden(comment.id);
    queryClient.setQueryData(
      queryKey.value,
      (oldData: InfiniteData<any | null, number>) => {
        if (!oldData) return oldData;

        return {
          ...oldData,
          pages: oldData.pages.map((page) => ({
            ...page,
            views: page.views.map((view: CommentViewNode) => {
              const updatedChildren = view.children?.map((child) =>
                child.id === comment.id
                  ? { ...child, hidden: !child.hidden }
                  : child,
              );

              const updatedView =
                view.id === comment.id
                  ? { ...view, hidden: !view.hidden }
                  : view;

              return {
                ...updatedView,
                children: updatedChildren,
              };
            }),
          })),
        };
      },
    );
    if (isChildThread) {
      commentStore.onSetSingleThreadViewItem({
        ...comment,
        hidden: false,
      });
    }
  };

  const onSaveCommentIndex = (fullPath: string, index: number) => {
    commentStore.onSaveCommentIndexInFeed(fullPath, index);
  };

  return {
    createComment,
    deleteComment,
    highlightedCommentId,
    isCommentDeleted,
    isCommentHighlighted,
    isCommenting,
    isEditing,
    isReplying,
    onSaveCommentIndex,
    onSetUserRank,
    onToggleThread,
    reactToComment,
    removeComment,
    repliesAvatar,
    repliesCount,
    report,
    setFeedNSFW,
    toggleEdit,
    toggleReply,
    translate,
    updateComment,
  };
};
